<template>
  <r-paper class="admin-category" round>
    <r-spinner v-if="isShowSpinner" />
    <div v-else>
      <r-modal-sub-header title="店舗一覧" />
      <!--店舗名を選択してください-->
      <div class="admin-category--main select-customer">
        <r-heading :level="4">{{ $t("auth.selectCustomerName") }}</r-heading>
        <div class="admin-select-store-checkbox">
          <r-select
            :label="selectLabel"
            :options="customers"
            @input="customerSelect"
            :error="errorFlag.customerNotSelected"
          />
        </div>
      </div>
      <!--店舗選択領域-->
      <r-spinner v-if="isShowSpinnerAtStoreList" class="admin-category--main" />
      <div v-else class="admin-select-store-wrapper">
        <div
          class="admin-category--main select-store-wrapper"
          :class="errorFlag.storeNotSelected ? ' store-not-selected' : ''"
        >
          <div v-if="stores.length <= 0 && customerID">
            該当顧客の店舗登録件数：０件
          </div>
          <r-checkbox :items="stores" @toggle="toggle" />
        </div>

        <!--編集及び削除ボタン-->
        <div class="admin-select-btn-wrapper">
          <r-button
            class="admin-category--button"
            buttonStyle="secondary"
            full
            @click="selectStore"
            >{{ $t("admin.storeList.editStore") }}</r-button
          >
          <r-button
            class="admin-category--button"
            buttonStyle="secondary"
            full
            @click="deleteStore"
            >{{ $t("admin.storeList.deleteStore") }}</r-button
          >
          <r-button
            class="admin-category--button"
            buttonStyle="secondary"
            full
            @click="connectSmaregi"
            >{{ $t("admin.storeList.connectSmaregi") }}</r-button
          >
        </div>
      </div>
    </div>
  </r-paper>
</template>
<script>
import RButton from "@/plugins/atoms/RButton.vue";
import RCheckbox from "@/plugins/atoms/RCheckbox.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RModalSubHeader from "@/plugins/molecules/RModalSubHeader.vue";
import RSelect from "@/plugins/atoms/RSelect.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import auth from "@/libs/Auth.js";
import customerManager from "@/libs/Customer.js";
import storeManager from "@/libs/Store.js";
import smaregiManager from "@/libs/Smaregi.js";

export default {
  components: {
    RButton,
    RCheckbox,
    RPaper,
    RHeading,
    RModalSubHeader,
    RSelect,
    RSpinner,
  },
  data() {
    return {
      selectLabel: "顧客名称を選択してください。",
      customerID: "",
      customers: {},
      stores: [],
      inputName: "",
      errorFlag: {
        customerNotSelected: false,
        storeNotSelected: false,
      },
      isShowSpinner: false,
      isShowSpinnerAtStoreList: false,
    };
  },
  methods: {
    toggle(index) {
      this.$set(this.stores[index], "bool", !this.stores[index].bool);
    },
    async customerSelect(value) {
      if (value) {
        this.isShowSpinnerAtStoreList = true;
        this.customerID = value;
        const storeList = await storeManager.getStoreList(this.customerID);
        this.stores = storeList.map((store) => {
          const recoed = { id: store.id, name: store.name, bool: false };
          return recoed;
        });
        this.isShowSpinnerAtStoreList = false;
      }
    },
    selectStore() {
      // 複数選択されていた場合、メッセージを表示する
      if (this.customerID === "") {
        //顧客名の選択漏れのチェック
        alert(
          "入力漏れ、もしくは選択漏れがあります。顧客の選択及び店舗の選択を行ってください。",
        );
        this.errorFlag.customerNotSelected = true;
        return;
      }
      //顧客errorFlag初期化
      this.errorFlag.customerNotSelected = false;
      if (this.stores.filter((e) => e.bool).length >= 2) {
        alert(this.$t("admin.customerList.selectOneCustomerInCaseOfEdit"));
        return;
      } else if (this.stores.filter((e) => e.bool).length <= 0) {
        this.errorFlag.storeNotSelected = true;
        alert(
          "入力漏れ、もしくは選択漏れがあります。顧客の選択及び店舗の選択を行ってください。",
        );
        return;
      }
      //編集画面
      const index = this.stores.map((e) => e.bool).indexOf(true);
      this.$emit("edit", this.customerID, this.stores[index].id);
    },
    async deleteStore() {
      if (this.customerID === "") {
        //顧客名の選択漏れのチェック
        alert(
          "入力漏れ、もしくは選択漏れがあります。顧客の選択及び店舗の選択を行ってください。",
        );
        this.errorFlag.customerNotSelected = true;
        return;
      }
      //顧客errorFlag初期化
      this.errorFlag.customerNotSelected = false;
      if (this.stores.filter((e) => e.bool).length <= 0) {
        //顧客及び店舗の選択チェック
        this.errorFlag.storeNotSelected = true;
        alert(
          "入力漏れ、もしくは選択漏れがあります。顧客の選択及び店舗の選択を行ってください。",
        );
        return;
      }
      const confirm = window.confirm(
        "顧客のアカウント情報を削除します。よろしいですか？",
      );
      if (confirm) {
        try {
          this.isShowSpinner = true;
          await Promise.all(
            this.stores.map(async (store) => {
              if (store.bool) {
                await storeManager.deleteStore(this.customerID, store.id);
              }
            }),
          );
          //現在のページをreload
          this.$router.go({
            path: this.$router.currentRoute.path,
            force: true,
          });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          throw new Error(error);
        }
        //削除成功
        alert("店舗情報の削除を完了しました。");
      }
    },
    async connectSmaregi() {
      //顧客errorFlag初期化
      this.errorFlag.customerNotSelected = false;
      if (this.customerID === "") {
        //顧客名の選択漏れのチェック
        alert(
          "入力漏れ、もしくは選択漏れがあります。顧客の選択及び店舗の選択を行ってください。",
        );
        this.errorFlag.customerNotSelected = true;
        return;
      }

      this.isShowSpinner = true;
      const customer = await customerManager.getCustomerInfo(this.customerID);
      if (
        !customer.smaregiClientID ||
        !customer.smaregiContractID ||
        !customer.smaregiSecret
      ) {
        alert(
          "スマレジの情報が登録されていません。契約情報を登録してください。",
        );
        this.isShowSpinner = false;
        return;
      }
      await smaregiManager.updateSmaregi(this.customerID);
      alert("スマレジへの同期が完了しました。");
      this.isShowSpinner = false;
    },
  },
  async created() {
    this.isShowSpinner = true;
    try {
      const isAdmin = await auth.isAdmin();
      if (!isAdmin) {
        throw new Error("Forbidden");
      }
      // 顧客一覧を表示
      const getCustomerList = await customerManager.getCustomerList();
      let records = {};
      getCustomerList.map(async (doc) => {
        records[doc.name] = doc.id;
      });
      this.customers = records;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    this.isShowSpinner = false;
  },
  beforeDestroy() {
    this.isShowSpinner = false;
  },
};
</script>

<style scoped>
.admin-category {
  padding: 20px;
}
.admin-category--main {
  padding: 10px;
}
.admin-category--button {
  margin-top: 20px;
}
.select-customer {
  padding: 0px;
}
.select-store-wrapper {
  text-align: left;
  border: 2px solid #e6e6e6;
  border-radius: 5px;
  margin-top: 20px;
  min-height: 25vh;
  overflow: auto;
}
.admin-select-store-checkbox {
  display: block;
  text-align: left;
  margin: 10px 0px 20px 0px;
}
.store-not-selected {
  border: 2px solid #e85951;
}
@media screen and (min-width: 641px) {
  .admin-select-store-wrapper {
    margin-top: 10px;
    display: grid;
    vertical-align: middle;
    grid-template-columns: 5fr 1fr;
    gap: 10px;
    min-height: 300px;
  }
  .select-store-wrapper {
    margin-top: unset;
    grid-template-columns: 5fr;
  }
  .admin-select-btn-wrapper {
    grid-template-columns: 1fr;
    margin: auto;
    display: block;
  }
  .admin-category--button {
    padding: 5px;
  }
  .admin-category--button:nth-of-type(1) {
    margin-top: unset;
  }
}
</style>
