<template>
  <div>
    <div v-if="active" class="r-tab">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "r-tab",
  inheritAttrs: false,
  props: {
    label: {
      default: "label",
      type: String,
    },
  },
  data() {
    return {
      id: null,
      active: false,
    };
  },
  mounted() {
    this.id = this.$parent.children.length;
    this.$parent.children.push({
      label: this.label,
      id: this.$parent.children.length,
      listeners: this.$listeners,
      attrs: this.$attrs,
    });
  },
};
</script>
