import { functions } from "@/libs";
import { httpsCallable } from "firebase/functions";

export default {
  async createStore(storeInfo) {
    try {
      if (!storeInfo) {
        throw new Error("No storeInfo");
      }
      const createAdminStore = httpsCallable(functions, "createAdminStore");
      await createAdminStore({
        customerID: storeInfo.customerID,
        name: storeInfo.storeName,
        infomartUser: storeInfo.infomartUser || "",
        infomartPassword: storeInfo.infomartPassword || "",
        pica1Cd: storeInfo.pica1Cd || 0,
        smaregiStoreID: storeInfo.smaregiStoreID || "",
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("ERROR", error);
      throw new Error(error);
    }
  },
  async getStoreList(customerID) {
    let storeList = [];

    try {
      const getAdminStoreList = httpsCallable(functions, "getAdminStoreList");
      const getdata = await getAdminStoreList({
        customerID: customerID,
      });
      if (getdata.data) {
        storeList = getdata.data;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }

    return storeList;
  },
  async getStoreInfo(customerID, storeID) {
    let storeInfo = {};
    try {
      if (!customerID || !storeID) {
        throw new Error("Customer ID and Store ID are required");
      }

      const getAdminStoreInfo = httpsCallable(functions, "getAdminStoreInfo");
      const resp = await getAdminStoreInfo({
        customerID: customerID,
        id: storeID,
      });
      if (resp.data) {
        storeInfo = resp.data;
        storeInfo.id = storeID;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }

    return storeInfo;
  },
  async updateStore(storeInfo) {
    try {
      if (!storeInfo) {
        throw new Error("No storeInfo for updating");
      }
      const updateAdminStore = httpsCallable(functions, "updateAdminStore");
      await updateAdminStore({
        id: storeInfo.id,
        customerID: storeInfo.customerID,
        name: storeInfo.storeName,
        infomartUser: storeInfo.infomartUser || "",
        infomartPassword: storeInfo.infomartPassword || "",
        pica1Cd: storeInfo.pica1Cd || 0,
        smaregiStoreID: storeInfo.smaregiStoreID || "",
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("ERROR", error);
      throw new Error(error);
    }
  },
  async deleteStore(customerID, storeID) {
    try {
      if (!storeID) {
        throw new Error("Store ID is required");
      }
      const deleteAdminStore = httpsCallable(functions, "deleteAdminStore");
      await deleteAdminStore({
        customerID: customerID,
        id: storeID,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("ERROR", error);
      throw new Error(error);
    }
  },
};
