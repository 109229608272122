<template>
  <div id="app">
    <r-header-for-login v-if="isLoginPage" />
    <r-header v-else />
    <div class="main">
      <router-view />
    </div>
    <r-footer class="footer"></r-footer>
  </div>
</template>

<script>
import RHeader from "@/components/design/RHeader.vue";
import RHeaderForLogin from "@/components/design/RHeaderForLogin.vue";
import RFooter from "@/components/design/RFooter.vue";

export default {
  components: {
    RHeader,
    RHeaderForLogin,
    RFooter,
  },
  computed: {
    isLoginPage() {
      //ログイン時にheaderを隠す処理用
      if (this.$route.path === "/" || this.$route.path === "/logout") {
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
#app {
  font-family: "Noto Sans Japanese", "メイリオ", "Meiryo", "ヒラギノ角ゴ Pro W3",
    "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", Helvetica,
    Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f0f0f0;
  position: relative;
  min-height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.main {
  flex: 1;
}
.footer {
  margin-top: 20px;
}
.modal-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
</style>
