<template>
  <r-modal
    class="admin-editcustomer"
    :title="
      $t('admin.customerList.customer') + $t('admin.userEditForm.userEdit')
    "
    cancelHidden
  >
    <r-spinner v-if="isShowSpinner" />
    <div v-else>
      <div class="admin-editcustomer--main" v-if="!confirm">
        <!--顧客名称-->
        <div class="admin-editcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.customerName") }}
          </r-heading>
          <div class="wrapper-for-textbox">
            <r-textbox
              type="string"
              v-model="customerName"
              :placeholder="isCustomerNameInput ? '' : '顧客名称を入力'"
              name="customerName"
              :error="errorFlag.nameInvalid"
            />
          </div>
        </div>
        <!--使用期限-->
        <div class="admin-editcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.expireDate") }}
          </r-heading>
          <Datepicker
            :language="ja"
            :format="DatePickerFormat"
            :wrapper-class="$style.datepickerwarapper"
            :input-class="[
              $style.datepickerinput,
              errorFlag.dateInvalid ? $style.invalid : '',
            ]"
            :placeholder="isExpireDateSelected ? '' : '使用期限を選択'"
            v-model="expireDate"
            name="expireDate"
            :error="errorFlag.dateInvalid"
          />
        </div>
        <!--企業番号-->
        <div class="admin-editcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.corpNo") }}
          </r-heading>
          <div class="wrapper-for-textbox">
            <r-textbox
              class="admin-createcustomer--main--content--item"
              type="string"
              v-model="corpNo"
              name="corpNo"
              :placeholder="isCorpNoInput ? '' : 'ぴかいちナビ企業番号を入力'"
              :error="errorFlag.corpNoInvalid"
              :disabled="!canCorpNoInput"
            />
          </div>
        </div>

        <!-- スマレジ連携情報 -->
        <!-- 契約ID -->
        <r-heading :level="4">
          {{ $t("admin.customerList.smaregiContractID") }}
          <span v-if="isSmaregiAnyInput" class="required-mark">&nbsp;*</span>
        </r-heading>
        <div class="edit-customer-id-pw-input-wrapper">
          <div class="wrapper-for-textbox">
            <r-textbox
              class="admin-editcustomer--main--content--item"
              type="string"
              v-model="smaregiContractID"
              name="smaregiContractID"
              :placeholder="'スマレジ契約ID'"
              :disabled="!canSmaregiInput"
            />
          </div>
        </div>

        <!-- クライアントID -->
        <r-heading :level="4">
          {{ $t("admin.customerList.smaregiClientID") }}
          <span v-if="isSmaregiAnyInput" class="required-mark">&nbsp;*</span>
        </r-heading>
        <div class="edit-customer-id-pw-input-wrapper">
          <div class="wrapper-for-textbox">
            <r-textbox
              class="admin-editcustomer--main--content--item"
              type="string"
              v-model="smaregiClientID"
              name="smaregiClientID"
              :placeholder="'スマレジクライアントID'"
              :disabled="!canSmaregiInput"
            />
          </div>
        </div>

        <!-- シークレットキー -->
        <r-heading :level="4">
          {{ $t("admin.customerList.smaregiSecret") }}
          <span v-if="isSmaregiAnyInput" class="required-mark">&nbsp;*</span>
        </r-heading>
        <div class="edit-customer-id-pw-input-wrapper">
          <div class="wrapper-for-textbox">
            <r-textbox
              class="admin-editcustomer--main--content--item"
              type="string"
              v-model="smaregiSecret"
              name="smaregiSecret"
              :placeholder="'スマレジシークレットキー'"
              :disabled="!canSmaregiInput"
            />
          </div>
        </div>

        <div class="admin-editcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.fooding") }}
          </r-heading>
          <div class="wrapper-for-textbox">
            <r-textbox
              class="admin-createcustomer--main--content--item"
              type="string"
              v-model="fooding"
              name="fooding"
              :placeholder="
                isFoodingInput ? '' : 'JSON形式でユーザID,パスワードを登録'
              "
            />
          </div>
        </div>
        <div class="admin-editcustomer--main--content">
          <r-heading :level="4"> API連携 </r-heading>
          <div class="edit-customer-id-pw-input-wrapper">
            <div class="wrapper-for-textbox id-pw-input-area">
              <!--APIキーの発行及び確認-->
              <r-textbox
                v-if="apiKey"
                class="admin-createcustomer--main--content--item"
                type="string"
                v-model="apiKey"
                name="apiKey"
              />
              <r-textbox
                v-else
                class="admin-createcustomer--main--content--item"
                type="string"
                v-model="apiKey"
                name="apiKey"
                :placeholder="apiKey ? '' : 'APIキーを発行'"
              />
            </div>
            <!--生成ボタン-->
            <r-button
              v-if="apiKey"
              class="admin-editcustomer--main--content id-create-btn"
              buttonStyle="secondary"
              @click="generateApiKey"
              >APIキーを再発行</r-button
            >
            <r-button
              v-else
              class="admin-editcustomer--main--content id-create-btn"
              buttonStyle="secondary"
              @click="generateApiKey"
              >APIキーを発行</r-button
            >
          </div>
        </div>
        <div class="admin-editcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.sharedMode") }}
          </r-heading>
          <div class="admin-editcustomer-checkbox">
            <input type="checkbox" id v-model="sharedMode" />
            {{ $t("admin.customerList.sharedMode") }}
          </div>
        </div>
        <div class="admin-editcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.level5") }}
          </r-heading>
          <div class="admin-editcustomer-checkbox">
            <input type="checkbox" id v-model="level5" />
            {{ $t("admin.customerList.chdckLevel5") }}
          </div>
        </div>
        <div class="admin-editcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.allergyTwenty") }}
          </r-heading>
          <div class="admin-editcustomer-checkbox">
            <input type="checkbox" id v-model="allergyTwenty" />
            {{ $t("admin.customerList.chdckAllergyTwenty") }}
          </div>
        </div>
        <div class="admin-editcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.smaregiHeadquarter") }}
          </r-heading>
          <div class="admin-editcustomer-checkbox">
            <input
              type="radio"
              id="one"
              value="false"
              v-model="smaregiHeadquarter"
              name="smaregiHeadquarter"
            />
            <label for="one">{{
              $t("admin.customerList.chdckSmaregiModeStore")
            }}</label>
            <br />
            <input
              type="radio"
              id="two"
              value="true"
              v-model="smaregiHeadquarter"
              name="smaregiHeadquarter"
            />
            <label for="two">{{
              $t("admin.customerList.chdckSmaregiModeHeadquarter")
            }}</label>
          </div>
        </div>
        <!--本部ユーザid/パスワード-->
        <r-heading :level="4">
          {{ $t("admin.customerList.headquartersUser") }}ID
        </r-heading>
        <div class="admin-editcustomer--main--content">
          <div class="edit-customer-id-pw-input-wrapper">
            <div class="wrapper-for-textbox id-pw-input-area">
              <r-textbox
                class="admin-editcustomer--main--content--item"
                type="string"
                v-model="headquartersUser"
                name="headquartersUser"
                :placeholder="
                  isHeadquartersUserInput ? '' : '本部ユーザ名を入力'
                "
                :error="errorFlag.headquartersUserInvalid"
              />
            </div>
            <r-button
              class="admin-editcustomer--main--content id-create-btn"
              buttonStyle="secondary"
              full
              @click="generateUserID"
              >{{ $t("admin.customerList.generateUserID") }}</r-button
            >
          </div>
        </div>
        <!--パスワード-->
        <r-heading :level="4">
          {{ $t("admin.customerList.headquartersUser") }}パスワード
        </r-heading>
        <div class="edit-customer-id-pw-input-wrapper">
          <div class="wrapper-for-textbox id-pw-input-area">
            <r-textbox
              class="admin-editcustomer--main--content--item"
              type="string"
              v-model="headquartersPassword"
              name="headquartersPassword"
              placeholder="パスワードを編集しない場合は空欄のままにしてください"
            />
          </div>
          <r-button
            class="admin-editcustomer--main--content id-create-btn"
            buttonStyle="secondary"
            full
            @click="generatePassword"
            >{{ $t("admin.customerList.generatePassword") }}</r-button
          >
        </div>
        <!--ユーザー表示名-->
        <r-heading :level="4">
          {{ $t("admin.customerList.displayName") }}
        </r-heading>
        <div class="edit-customer-id-pw-input-wrapper">
          <div class="wrapper-for-textbox">
            <r-textbox
              class="admin-editcustomer--main--content--item"
              type="string"
              v-model="displayName"
              name="displayName"
              :placeholder="isDisplayNameInput ? '' : '本部ユーザ表示名'"
              :error="errorFlag.displayNameInvalid"
            />
          </div>
        </div>

        <div class="admin-editcustomer--main--content button-wrapper">
          <!--入力確認ボタン-->
          <r-button
            class="admin-editcustomer--main--content input-confirm-btn"
            buttonStyle="primary"
            full
            @click="inputConfirm"
            >{{ $t("admin.userEditForm.inputConfirm") }}</r-button
          >
        </div>
      </div>

      <!-- 確認画面ここから -->
      <div class="admin-editcustomer--main confirm-edit-info" v-else>
        <div class="admin-editcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.customerName") }}
          </r-heading>
          <div class="editcustomer-content-list">{{ customerName }}</div>
        </div>
        <div class="admin-editcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.expireDate") }}
          </r-heading>
          <div class="editcustomer-content-list">{{ formateDate }}</div>
        </div>
        <div class="admin-editcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.corpNo") }}
          </r-heading>
          <div v-if="isCorpNoInput" class="editcustomer-content-list">
            {{ corpNo }}
          </div>
          <div v-else class="editcustomer-content-list">未入力</div>
        </div>
        <div class="admin-editcustomer--main--content">
          <!--スマレジ契約ID-->
          <r-heading :level="4">
            {{ $t("admin.customerList.smaregiContractID") }}
          </r-heading>
          <div
            v-if="isSmaregiContractIDInput"
            class="editcustomer-content-list"
          >
            {{ smaregiContractID }}
          </div>
          <div v-else class="editcustomer-content-list">未入力</div>
        </div>
        <div class="admin-editcustomer--main--content">
          <!--スマレジ クライアントID-->
          <r-heading :level="4">
            {{ $t("admin.customerList.smaregiClientID") }}
          </r-heading>
          <div v-if="isSmaregiClientIDInput" class="editcustomer-content-list">
            {{ smaregiClientID }}
          </div>
          <div v-else class="editcustomer-content-list">未入力</div>
        </div>
        <div class="admin-editcustomer--main--content">
          <!--スマレジ シークレットキー-->
          <r-heading :level="4">
            {{ $t("admin.customerList.smaregiSecret") }}
          </r-heading>
          <div v-if="isSmaregiSecretInput" class="editcustomer-content-list">
            {{ smaregiSecret }}
          </div>
          <div v-else class="editcustomer-content-list">未入力</div>
        </div>
        <div class="admin-editcustomer--main--content">
          <!--スマレジ クライアントID-->
          <r-heading :level="4">
            {{ $t("admin.customerList.smaregiHeadquarter") }}
          </r-heading>
          <div
            v-if="isSmaregiHeadquarterCheck"
            class="editcustomer-content-list"
          >
            {{ $t("admin.customerList.chdckSmaregiModeHeadquarter") }}
          </div>
          <div v-else class="editcustomer-content-list">
            {{ $t("admin.customerList.chdckSmaregiModeStore") }}
          </div>
        </div>
        <div class="admin-editcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.fooding") }}
          </r-heading>
          <div v-if="isFoodingInput" class="editcustomer-content-list">
            {{ fooding }}
          </div>
          <div v-else class="editcustomer-content-list">未入力</div>
        </div>
        <div class="admin-editcustomer--main--content">
          <r-heading :level="4"> API連携 </r-heading>
          <div v-if="apiKey" class="editcustomer-content-list">
            {{ apiKey }}
          </div>
          <div v-else class="editcustomer-content-list">未入力</div>
        </div>
        <div class="admin-editcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.sharedMode") }}
          </r-heading>
          <div v-if="sharedMode" class="editcustomer-content-list">
            {{ $t("common.use") }}
          </div>
          <div v-else class="editcustomer-content-list">
            {{ $t("common.unUse") }}
          </div>
        </div>
        <div class="admin-editcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.level5") }}
          </r-heading>
          <div v-if="level5" class="editcustomer-content-list">
            {{ $t("common.use") }}
          </div>
          <div v-else class="editcustomer-content-list">
            {{ $t("common.unUse") }}
          </div>
        </div>
        <div class="admin-editcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.allergyTwenty") }}
          </r-heading>
          <div v-if="allergyTwenty" class="editcustomer-content-list">
            {{ $t("common.use") }}
          </div>
          <div v-else class="editcustomer-content-list">
            {{ $t("common.unUse") }}
          </div>
        </div>
        <div class="admin-editcustomer--main--content">
          <!--id-->
          <r-heading :level="4">
            {{ $t("admin.customerList.headquartersUser") }}id
          </r-heading>
          <div class="editcustomer-content-list">{{ headquartersUser }}</div>
        </div>
        <div class="admin-editcustomer--main--content">
          <!--password-->
          <r-heading :level="4">
            {{ $t("admin.customerList.headquartersPassword") }}
          </r-heading>
          <div class="editcustomer-content-list">
            {{ headquartersPassword || headquartersPsswordNotChange }}
          </div>
        </div>
        <div class="admin-editcustomer--main--content">
          <!--表示名-->
          <r-heading :level="4">
            {{ $t("admin.customerList.displayName") }}
          </r-heading>
          <div class="editcustomer-content-list">{{ displayName }}</div>
        </div>
        <div class="admin-editcustomer--main--content button-wrapper-second">
          <r-button
            class="admin-editcustomer--main--content--button input-confirm-btn"
            buttonStyle="primary"
            full
            @click="regist"
            >{{ $t("admin.userEditForm.completeEditUser") }}</r-button
          >
          <r-button
            class="admin-editcustomer--main--content--button input-confirm-btn"
            buttonStyle="primary"
            full
            outline
            @click="inputCorrection"
            >{{ $t("admin.userEditForm.inputCorrection") }}</r-button
          >
        </div>
      </div>
    </div>
  </r-modal>
</template>
<script>
import RTextbox from "@/plugins/atoms/RTextbox.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import RModal from "@/plugins/molecules/RModal.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import auth from "@/libs/Auth.js";
import customerManager from "@/libs/Customer.js";
import userManager from "@/libs/User.js";

import Datepicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";

export default {
  components: {
    RTextbox,
    RButton,
    RModal,
    RHeading,
    Datepicker,
    RSpinner,
  },
  props: {
    editCustomerID: String,
  },
  data() {
    return {
      smaregiHeadquarter: false,
      display: false,
      displayConfirm: false,
      confirm: false,
      id: "",
      customerName: "",
      expireDate: null,
      corpNo: "",
      fooding: "",
      apiKey: "",
      sharedMode: false,
      level5: false,
      allergyTwenty: false,
      headquartersUser: "",
      headquartersPassword: "",
      headquartersPsswordNotChange: "パスワード編集なし",
      displayName: "",
      smaregiContractID: "",
      smaregiClientID: "",
      smaregiSecret: "",
      uid: "",
      ja: ja,
      DatePickerFormat: "yyyy/MM/dd",
      errorFlag: {
        nameInvalid: false,
        dateInvalid: false,
        corpNoInvalid: false,
        smaregiContractIDInvalid: false,
        smaregiClientIDInvalid: false,
        smaregiSecretInvalid: false,
        headquartersUserInvalid: false,
        displayNameInvalid: false,
      },
      isShowSpinner: false,
    };
  },
  computed: {
    formateDate() {
      if (this.expireDate) {
        return `${this.expireDate.getFullYear()}/${
          this.expireDate.getMonth() + 1
        }/${this.expireDate.getDate()}`;
      } else {
        return null;
      }
    },
    isCustomerNameInput() {
      if (this.customerName !== "") {
        return true;
      } else {
        return false;
      }
    },
    isExpireDateSelected() {
      if (this.expireDate !== "") {
        return true;
      } else {
        return false;
      }
    },
    isCorpNoInput() {
      if (this.corpNo !== "") {
        return true;
      } else {
        return false;
      }
    },
    isFoodingInput() {
      if (this.fooding !== "") {
        return true;
      } else {
        return false;
      }
    },
    isHeadquartersUserInput() {
      if (this.headquartersUser !== "") {
        return true;
      } else {
        return false;
      }
    },
    isDisplayNameInput() {
      if (this.displayName !== "") {
        return true;
      } else {
        return false;
      }
    },
    isSmaregiContractIDInput() {
      if (this.smaregiContractID) {
        return true;
      } else {
        return false;
      }
    },
    isSmaregiClientIDInput() {
      if (this.smaregiClientID) {
        return true;
      } else {
        return false;
      }
    },
    isSmaregiSecretInput() {
      if (this.smaregiSecret) {
        return true;
      } else {
        return false;
      }
    },
    isSmaregiHeadquarterCheck() {
      return this.smaregiHeadquarter;
    },
    isSmaregiAnyInput() {
      if (
        this.isSmaregiContractIDInput ||
        this.isSmaregiClientIDInput ||
        this.isSmaregiSecretInput
      ) {
        return true;
      } else {
        return false;
      }
    },
    canCorpNoInput() {
      if (
        !this.isSmaregiContractIDInput &&
        !this.isSmaregiClientIDInput &&
        !this.isSmaregiSecretInput
      ) {
        return true;
      } else {
        return false;
      }
    },
    canSmaregiInput() {
      if (!this.isCorpNoInput) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    inputConfirm() {
      if (
        this.isCustomerNameInput &&
        this.isExpireDateSelected &&
        // this.isCorpNoInput &&
        this.isHeadquartersUserInput &&
        this.isDisplayNameInput &&
        (this.isSmaregiAnyInput
          ? this.isSmaregiContractIDInput &&
            this.isSmaregiClientIDInput &&
            this.isSmaregiSecretInput
          : true)
      ) {
        //確認画面に転換
        this.confirm = true;
        //エラーflag初期化
        this.errorFlag.nameInvalid = false;
        this.errorFlag.dateInvalid = false;
        this.errorFlag.corpNoInvalid = false;
        this.errorFlag.headquartersUserInvalid = false;
        this.errorFlag.displayNameInvalid = false;
        this.errorFlag.smaregiContractIDInvalid = false;
        this.errorFlag.smaregiClientIDInvalid = false;
        this.errorFlag.smaregiSecretInvalid = false;
      } else {
        //顧客名称
        if (!this.isCustomerNameInput) {
          this.errorFlag.nameInvalid = true;
        } else {
          this.errorFlag.nameInvalid = false;
        }
        //使用期限
        if (!this.isExpireDateSelected) {
          this.errorFlag.dateInvalid = true;
        } else {
          this.errorFlag.dateInvalid = false;
        }
        //本部ユーザ
        if (!this.isHeadquartersUserInput) {
          this.errorFlag.headquartersUserInvalid = true;
        } else {
          this.errorFlag.headquartersUserInvalid = false;
        }
        //表示名
        if (!this.isDisplayNameInput) {
          this.errorFlag.displayNameInvalid = true;
        } else {
          this.errorFlag.displayNameInvalid = false;
        }

        // スマレジ情報
        if (this.isSmaregiAnyInput) {
          if (!this.isSmaregiContractIDInput) {
            this.errorFlag.smaregiContractIDInvalid = true;
          }

          if (!this.isSmaregiClientIDInput) {
            this.errorFlag.smaregiClientIDInvalid = true;
          }

          if (!this.isSmaregiSecretInput) {
            this.errorFlag.smaregiSecretInvalid = true;
          }
        }

        alert("入力漏れ、もしくは選択漏れがあります。");
      }
    },
    inputCorrection() {
      this.confirm = false;
    },
    async regist() {
      this.isShowSpinner = true;
      try {
        // 顧客情報をupdate
        const custoerInfo = {
          id: this.editCustomerID,
          customerName: this.customerName,
          expireDate: this.formateDate,
          corpNo: this.corpNo,
          fooding: this.fooding,
          apiKey: this.apiKey,
          copy: this.sharedMode,
          level5: this.level5,
          allergyTwenty: this.allergyTwenty,
          uid: this.uid,
          userID: this.headquartersUser,
          password: this.headquartersPassword,
          displayName: this.displayName,
          smaregiContractID: this.smaregiContractID,
          smaregiClientID: this.smaregiClientID,
          smaregiSecret: this.smaregiSecret,
          smaregiHeadquarter: this.smaregiHeadquarter,
        };
        // ユーザ情報を更新
        await customerManager.updateCustomer(custoerInfo);
        this.$emit("updateCustomerComplete");
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("ERROR", error);
      }
      this.isShowSpinner = false;
    },
    generateRandomString(limit) {
      return userManager.generateRandomString(limit);
    },
    generateUserID() {
      this.headquartersUser = this.generateRandomString(8);
    },
    generatePassword() {
      this.headquartersPassword = this.generateRandomString(10);
    },
    generateApiKey() {
      this.apiKey = userManager.generateApiKey();
    },
  },
  async created() {
    this.isShowSpinner = true;
    try {
      const isAdmin = await auth.isAdmin();
      if (!isAdmin) {
        throw new Error("Forbidden");
      }

      // 顧客情報を取得
      const customerInfo = await customerManager.getCustomerInfo(
        this.editCustomerID,
      );

      // データをセット
      this.id = customerInfo.id;
      this.customerName = customerInfo.name;
      if (customerInfo.expireDate) {
        this.expireDate = new Date(customerInfo.expireDate);
      }
      this.corpNo = customerInfo.corpNo;
      this.fooding = JSON.stringify(customerInfo.fooding);
      this.apiKey = customerInfo.apiKey;
      this.sharedMode = customerInfo.copy;
      this.level5 = customerInfo.level5;
      this.allergyTwenty = customerInfo.allergyTwenty;
      this.headquartersUser = customerInfo.userID;
      this.displayName = customerInfo.displayName;
      (this.smaregiContractID = customerInfo.smaregiContractID),
        (this.smaregiClientID = customerInfo.smaregiClientID),
        (this.smaregiSecret = customerInfo.smaregiSecret);
      this.uid = customerInfo.uid;
      this.smaregiHeadquarter = customerInfo.smaregiHeadquarter;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    this.isShowSpinner = false;
  },
};
</script>

<style scoped>
.admin-editcustomer {
  max-height: 90vh;
}
.config-editcustomer--main {
  text-align: left;
  padding: 20px;
}
.admin-editcustomer--main--content {
  margin-bottom: 10px;
}
.config-editcustomer--main--content--button {
  margin-top: 10px;
}
.config-editcustomer--main--content {
  margin-top: 20px;
}
.wrapper-for-textbox {
  margin-left: 20px;
}
.admin-editcustomer-checkbox {
  display: block;
  text-align: left;
  margin: 10px 0px 10px 20px;
}
.editcustomer-content-list {
  text-align: left;
  margin-left: 20px;
}
.button-wrapper {
  position: relative;
  bottom: -20px;
  margin-bottom: unset;
}
.admin-editcustomer--main--content--button {
  margin-top: 20px;
}
.button-wrapper-second {
  position: relative;
  bottom: -20px;
  margin-bottom: 20px !important;
}
.id-create-btn {
  margin-top: 20px;
}
.input-confirm-btn {
  margin-top: 0px;
  margin-bottom: 0 !important;
}
.input-confirm-btn:last-child {
  margin-top: 20px;
}
@media screen and (min-width: 641px) {
  .admin-editcustomer {
    max-height: 60vh;
  }
  .config-editcustomer--main {
    text-align: left;
    padding: 20px;
  }
  .admin-editcustomer--main--content {
    margin-bottom: 20px;
  }
  .config-editcustomer--main--content--button {
    margin-top: 10px;
  }
  .config-editcustomer--main--content {
    margin-top: 20px;
  }
  .wrapper-for-textbox {
    margin-left: 20px;
    width: 60%;
  }
  .admin-editcustomer-checkbox {
    display: block;
    text-align: left;
    margin: 10px 0px 20px 20px;
  }
  .editcustomer-content-list {
    text-align: left;
    margin-left: 20px;
  }
  .admin-editcustomer--main--content--button {
    margin-top: 20px;
  }
  .button-wrapper {
    position: relative;
    bottom: -20px;
    margin-bottom: unset;
  }
  .edit-customer-id-pw-input-wrapper {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
  }
  .id-pw-input-area {
    width: 60%;
    margin-right: 20px;
  }
  .id-create-btn {
    width: 20%;
    height: 43px;
    margin-top: unset;
    margin-bottom: unset;
  }
  .button-wrapper-second {
    position: relative;
    bottom: -20px;
    margin-bottom: 0px !important;
  }
  .input-confirm-btn {
    margin-top: 20px;
    margin-bottom: 0 !important;
    padding: 5px;
  }
}
@media screen and (min-width: 1024px) {
  .admin-editcustomer {
    min-height: 80vh;
  }
  .button-wrapper {
    bottom: -15px;
  }
  .button-wrapper-second {
    bottom: -30px;
  }
}
</style>
<style module>
.datepickerwarapper {
  display: flex;
  justify-content: start;
  align-items: center;
}
.datepickerinput {
  border-radius: 4px;
  border: 2px solid #e6e6e6;
  padding: 8px;
  font-size: 1.2rem;
  width: 100%;
  margin-left: 20px;
}
.invalid {
  border-radius: 4px;
  border: 2px solid #e85951;
  padding: 8px;
  font-size: 1.2rem;
  width: 100%;
}
</style>
