<template>
  <div class="r-list">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "r-list",
};
</script>

<!-- テスト用 -->
<style scoped>
.r-list {
  background-color: #ffffff;
  width: 100%;
}
</style>
