<template>
  <div>
    <r-modal-sub-header :title="$t('admin.adminFileForm.formTitle')" />
    <div ref="xspreadsheet" id="xapp" />
    <!--登録ボタン-->
    <r-button
      class="admin-category--button"
      buttonStyle="secondary"
      full
      @click="registFile"
      >{{ $t("admin.adminFileForm.registMaster") }}</r-button
    >
    <!--登録spinner-->
    <div class="modal-bg loading-bg" v-if="isShowSpinner">
      <r-spinner></r-spinner>
    </div>
  </div>
</template>
<script>
import RButton from "@/plugins/atoms/RButton.vue";
import RModalSubHeader from "@/plugins/molecules/RModalSubHeader.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import auth from "@/libs/Auth.js";
import masterManager from "@/libs/Master.js";
import Spreadsheet from "x-data-spreadsheet";

export default {
  components: {
    RButton,
    RModalSubHeader,
    RSpinner,
  },
  props: {
    customerID: {
      type: String,
      required: true,
    },
    storeID: {
      type: String,
      required: true,
    },
    csv: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      editData: {},
      isShowSpinner: false,
    };
  },
  methods: {
    async registFile() {
      this.isShowSpinner = true;
      try {
        let uploadData = [];
        // 確認ファイルの再登録
        if (Object.keys(this.editData).length > 0) {
          // オブジェクトから配列に変換
          const tmpArray = Object.entries(this.editData);
          uploadData = tmpArray.reduce((acc, data, index) => {
            if (index > 0) {
              const lineData = data[1].cells;
              if (lineData) {
                const item = {
                  "［マイカタログID］": lineData[0].text,
                  "［食品小分類コード］": lineData[1].text,
                  "［食品大分類名］": lineData[2].text,
                  "［食品中分類名］": lineData[3].text,
                  "［食品小分類名］": lineData[4].text,
                  "［商品システムコード］": lineData[5].text,
                  "［商品名］": lineData[6].text,
                  "［規格］": lineData[7].text,
                  "［入数単位］": lineData[8].text,
                  "［単価］": lineData[9].text,
                  "［旧単価］": lineData[10].text,
                  "［取引先名］": lineData[11].text,
                  "［単価変更日］": lineData[12].text,
                  "［自社管理入数］": lineData[13].text,
                  "［自社管理入数単位］": lineData[14].text,
                  "［発注単価］": lineData[15].text,
                  "［発注単位］": lineData[16].text,
                  "［マイカタログ単価］": lineData[17].text,
                  "［マイカタログ旧単価］": lineData[18].text,
                  "［マイカタログ単位］": lineData[19].text,
                  "［マイカタログ変更日］": lineData[20].text,
                  "［食品大分類コード］": lineData[21].text,
                  "［食品中分類コード］": lineData[22].text,
                  "［取引先名カナ］": lineData[23].text,
                  "［商品名カナ］": lineData[24].text,
                  "［入数］": lineData[25].text,
                };
                acc.push(item);
              }
            }
            return acc;
          }, []);
        } else {
          // 何も編集していないのでそのままアップロード
          uploadData = this.csv;
        }
        // データを登録
        await masterManager.setMasterFile(
          this.customerID,
          this.storeID,
          uploadData,
        );
        alert("マスターファイルのアップロードが完了しました。");
        this.isShowSpinner = false;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("ERROR", error);
        throw new Error(error);
      }
    },
  },
  async mounted() {
    try {
      if (!this.csv || this.csv.length == 0) {
        throw new Error("Forbidden");
      }

      // データ数を取得
      const dataCount = this.csv.length + 1;

      // 取得したJSONをspreadsheet向けのJSONに変更
      const rows = this.csv.reduce((acc, data, index) => {
        const setData = Object.entries(data);
        const cells = setData.reduce((sacc, rowData, idx) => {
          sacc[idx] = { text: rowData[1] };
          return sacc;
        }, {});
        acc[index + 1] = { cells: cells };
        return acc;
      }, {});

      // ヘッダセット
      const hederRow = {
        cells: {
          0: { text: "［マイカタログID］" },
          1: { text: "［食品小分類コード］" },
          2: { text: "［食品大分類名］" },
          3: { text: "［食品中分類名］" },
          4: { text: "［食品小分類名］" },
          5: { text: "［商品システムコード］" },
          6: { text: "［商品名］" },
          7: { text: "［規格］" },
          8: { text: "［入数単位］" },
          9: { text: "［単価］" },
          10: { text: "［旧単価］" },
          11: { text: "［取引先名］" },
          12: { text: "［単価変更日］" },
          13: { text: "［自社管理入数］" },
          14: { text: "［自社管理入数単位］" },
          15: { text: "［発注単価］" },
          16: { text: "［発注単位］" },
          17: { text: "［マイカタログ単価］" },
          18: { text: "［マイカタログ旧単価］" },
          19: { text: "［マイカタログ単位］" },
          20: { text: "［マイカタログ変更日］" },
          21: { text: "［食品大分類コード］" },
          22: { text: "［食品中分類コード］" },
          23: { text: "［取引先名カナ］" },
          24: { text: "［商品名カナ］" },
          25: { text: "［入数］" },
        },
      };
      rows[0] = hederRow;
      // eslint-disable-next-line no-unused-vars
      const s = new Spreadsheet(this.$refs.xspreadsheet, {
        view: {
          height: () => 600,
          width: () => 660,
        },
        showToolbar: false,
        showContextmenu: false,
        showGrid: true,
        row: {
          len: dataCount,
        },
      })
        .loadData({
          rows: rows,
        }) // load data
        .change((data) => {
          // save data to db
          this.editData = data.rows;
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("ERROR", error);
    }
  },
  async created() {
    try {
      const isAdmin = await auth.isAdmin();
      if (!isAdmin) {
        throw new Error("Forbidden");
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
  },
};
</script>

<style scoped>
.admin-category--button {
  margin-top: 20px;
}
.modal-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.loading-bg {
  background: rgba(255, 255, 255, 0.5);
}
</style>
