<template>
  <r-paper round class="admin-createstore--main">
    <r-spinner v-if="isShowSpinner" />
    <div v-else>
      <r-modal-sub-header :title="$t('admin.storeList.storeRegistration')">
        <div v-if="!confirm">
          <div class="admin-createstore--main--content">
            <r-heading :level="4">
              顧客名を選択<span class="required-mark">&nbsp;*</span>
            </r-heading>
            <div class="admin-create-store-checkbox">
              <r-select
                :label="selectLabel"
                :options="customers"
                v-model="customerID"
                :error="errorFlag.customerNotSelected"
              />
            </div>
          </div>
          <div class="admin-createstore--main--content">
            <r-heading :level="4">
              {{ $t("admin.storeList.storeName")
              }}<span class="required-mark">&nbsp;*</span>
            </r-heading>
            <div class="wrapper-for-textbox">
              <r-textbox
                class="admin-createstore--main--content--item"
                type="string"
                v-model="storeName"
                name="storeName"
                :placeholder="isStoreNameInputed ? '' : '店舗名を入力'"
                :error="errorFlag.storeNameInvalid"
              />
            </div>
          </div>
          <div class="admin-createstore--main--content">
            <r-heading :level="4">
              {{ $t("admin.storeList.infomartUserName") }}
            </r-heading>
            <div class="wrapper-for-textbox">
              <r-textbox
                class="admin-createstore--main--content--item"
                type="string"
                v-model="infomartUser"
                name="infomartUser"
                :placeholder="isInfomartUserInputed ? '' : 'FTPユーザ名を入力'"
              />
            </div>
          </div>
          <div class="admin-createstore--main--content">
            <r-heading :level="4">
              {{ $t("admin.storeList.infomartPassword") }}
            </r-heading>
            <div class="wrapper-for-textbox">
              <r-textbox
                class="admin-createstore--main--content--item"
                type="string"
                v-model="infomartPassword"
                name="infomartPassword"
                :placeholder="
                  isInfomartPasswordInputed ? '' : 'FTPパスワードを入力'
                "
              />
            </div>
          </div>
          <div class="admin-createstore--main--content">
            <r-heading :level="4">
              {{ $t("admin.storeList.pica1Cd") }}
            </r-heading>
            <div class="wrapper-for-textbox">
              <r-textbox
                class="admin-createstore--main--content--item"
                type="number"
                v-model.number="pica1Cd"
                name="pica1Cd"
                :placeholder="
                  isPica1CdInputed ? '' : 'ぴかいちナビ店舗コードを入力'
                "
                :error="errorFlag.pica1CdInvalid"
                :disabled="isSmaregiStoreIDInputed"
              />
            </div>
          </div>

          <div class="admin-createstore--main--content">
            <r-heading :level="4">
              {{ $t("admin.storeList.smaregiStoreID") }}
            </r-heading>
            <div class="wrapper-for-textbox">
              <r-textbox
                class="admin-createstore--main--content--item"
                type="text"
                v-model="smaregiStoreID"
                name="smaregiStoreID"
                :placeholder="'スマレジ店舗IDを入力'"
                :error="errorFlag.pica1CdInvalid"
                :disabled="isPica1CdInputed"
              />
            </div>
          </div>

          <div class="admin-createstore--required-item-instruction">
            「<span class="required-mark">&nbsp;*&nbsp;</span>」は必須項目です。
          </div>
          <!--入力内容確認するボタン-->
          <r-button
            class="admin-createstore--main--content confirm-input-btn"
            buttonStyle="primary"
            full
            @click="inputConfirm"
            >{{ $t("admin.userEditForm.inputConfirm") }}</r-button
          >
        </div>
        <!--入力内容確認画面-->
        <div v-else>
          <dl class="admin-createstore-dl">
            <dt>店舗登録顧客名 <span class="required-mark">&nbsp;*</span></dt>
            <dd>{{ customerName }}</dd>
            <dt>
              {{ $t("admin.storeList.storeName") }}
              <span class="required-mark">&nbsp;*</span>
            </dt>
            <dd>{{ storeName }}</dd>
            <dt>{{ $t("admin.storeList.infomartUserName") }}</dt>
            <dd v-if="isInfomartUserInputed">{{ infomartUser }}</dd>
            <dd v-else>未入力</dd>
            <dt>{{ $t("admin.storeList.infomartPassword") }}</dt>
            <dd v-if="isInfomartPasswordInputed">{{ infomartPassword }}</dd>
            <dd v-else>未入力</dd>
            <dt>{{ $t("admin.storeList.pica1Cd") }}</dt>
            <dd v-if="isPica1CdInputed">{{ pica1Cd }}</dd>
            <dd v-else>未入力</dd>
            <dt>{{ $t("admin.storeList.smaregiStoreID") }}</dt>
            <dd v-if="isSmaregiStoreIDInputed">{{ smaregiStoreID }}</dd>
            <dd v-else>未入力</dd>
          </dl>
          <div class="btn-wrapper">
            <r-button
              buttonStyle="primary"
              @click="inputCorrection"
              class="admin-createstore-btn"
              >{{ $t("admin.userEditForm.inputCorrection") }}</r-button
            >
            <r-button
              buttonStyle="primary"
              @click="regist"
              class="admin-createstore-btn"
              >{{ $t("admin.userEditForm.regist") }}</r-button
            >
          </div>
        </div>
      </r-modal-sub-header>
    </div>
  </r-paper>
</template>
<script>
import RTextbox from "@/plugins/atoms/RTextbox.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RModalSubHeader from "@/plugins/molecules/RModalSubHeader.vue";
import RSelect from "@/plugins/atoms/RSelect.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import auth from "@/libs/Auth.js";
import customerManager from "@/libs/Customer.js";
import storeManager from "@/libs/Store.js";

export default {
  components: {
    RTextbox,
    RButton,
    RHeading,
    RPaper,
    RModalSubHeader,
    RSelect,
    RSpinner,
  },
  data() {
    return {
      display: false,
      displayConfirm: false,
      confirm: false,
      selectLabel: "顧客名称を選択してください。",
      customerID: "",
      customers: {},
      storeName: "",
      infomartUser: "",
      infomartPassword: "",
      pica1Cd: "",
      smaregiStoreID: "",
      errorFlag: {
        customerNotSelected: false,
        storeNameInvalid: false,
        infomartUserInvalid: false,
        infomartPassInvalid: false,
        pica1Invalid: false,
      },
      isShowSpinner: false,
    };
  },
  methods: {
    inputConfirm() {
      if (this.isCustomerSelected && this.isStoreNameInputed) {
        //確認画面に転換
        this.confirm = true;
        //エラーflag初期化
        this.errorFlag.customerNotSelected = false;
        this.errorFlag.storeNameInvalid = false;
      } else {
        //顧客
        if (!this.isCustomerSelected) {
          this.errorFlag.customerNotSelected = true;
        } else {
          this.errorFlag.customerNotSelected = false;
        }
        //店舗
        if (!this.isStoreNameInputed) {
          this.errorFlag.storeNameInvalid = true;
        } else {
          this.errorFlag.storeNameInvalid = false;
        }
        alert("入力漏れ、もしくは選択漏れがあります。");
      }
    },
    inputCorrection() {
      this.confirm = false;
    },
    async regist() {
      this.isShowSpinner = true;
      try {
        const storeInfo = {
          customerID: this.customerID,
          storeName: this.storeName,
          infomartUser: this.infomartUser,
          infomartPassword: this.infomartPassword,
          pica1Cd: this.pica1Cd,
          smaregiStoreID: this.smaregiStoreID,
        };
        // 本部店舗登録
        await storeManager.createStore(storeInfo);
        alert("店舗登録を完了しました。");
        this.isShowSpinner = false;
        this.$emit("createStoreComplete");
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("ERROR", error);
      }
    },
  },
  computed: {
    //店舗登録中の顧客名称表示用
    customerName() {
      return Object.keys(this.customers).find(
        (key) => this.customers[key] === this.customerID,
      );
    },
    isCustomerSelected() {
      if (this.customerID) {
        return true;
      } else {
        return false;
      }
    },
    isStoreNameInputed() {
      if (this.storeName) {
        return true;
      } else {
        return false;
      }
    },
    isInfomartUserInputed() {
      if (this.infomartUser) {
        return true;
      } else {
        return false;
      }
    },
    isInfomartPasswordInputed() {
      if (this.infomartPassword) {
        return true;
      } else {
        return false;
      }
    },
    isPica1CdInputed() {
      if (this.pica1Cd) {
        return true;
      } else {
        return false;
      }
    },
    isSmaregiStoreIDInputed() {
      if (this.smaregiStoreID) {
        return true;
      } else {
        return false;
      }
    },
  },
  async created() {
    this.isShowSpinner = true;
    try {
      const isAdmin = await auth.isAdmin();
      if (!isAdmin) {
        throw new Error("Forbidden");
      }

      // 顧客一覧を表示
      const getCustomerList = await customerManager.getCustomerList();
      let records = {};
      getCustomerList.map(async (doc) => {
        records[doc.name] = doc.id;
      });
      this.customers = records;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
    this.isShowSpinner = false;
  },
  beforeDestroy() {
    this.isShowSpinner = false;
  },
};
</script>

<style scoped>
.admin-createstore--main {
  padding: 20px;
}
.admin-createstore--required-item-instruction {
  text-align: left;
  margin-top: 20px;
}
.admin-createstore--main--content {
  margin-top: 20px;
}
.admin-createstore--main--content--item {
  margin-top: 10px;
}
.wrapper-for-textbox {
  margin-left: 20px;
}
.admin-createstore-dl {
  text-align: left;
}
dl dt {
  font-weight: bold;
  margin-bottom: 10px;
  margin-left: 10px;
}
dl dd {
  margin-bottom: 20px;
}
.btn-wrapper {
  display: grid;
  margin-top: 40px;
}
.admin-createstore-btn {
  margin-top: 20px;
  padding: 5px;
}
.admin-create-store-checkbox {
  display: block;
  text-align: left;
  margin: 10px 0px 20px 20px;
}
.required-mark {
  font-size: 1.2rem;
  color: #e85951;
}
@media screen and (min-width: 641px) {
  .confirm-input-btn {
    margin-top: 40px;
    padding: 5px;
  }
}
</style>
