<template>
  <div class="r-modal-sub-header">
    <div class="r-modal-sub-header--wrapper">
      <span class="r-modal-sub-header--title">{{ title }}</span>
    </div>
    <r-hr hrStyle="sub-primary" />
    <div class="r-modal-sub-content">
      <slot />
    </div>
  </div>
</template>

<script>
import RHr from "@/plugins/atoms/RHorizontalRule.vue";

export default {
  name: "r-modal-sub-header",
  components: {
    RHr,
  },
  props: {
    title: String,
  },
};
</script>

<style scoped>
.r-modal-sub-header {
  width: 100%;
  margin-top: 20px;
}
.r-modal-sub-header--wrapper {
  text-align: left;
  padding-left: 10px;
}
.r-modal-sub-header--title {
  font-weight: bold;
}
.r-modal-sub-content {
  margin-top: 20px;
}
</style>
