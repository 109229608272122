<template>
  <hr
    class="r-horizontal-rule"
    :class="{
      'r-horizontal-rule--primary': hrStyle === 'primary',
      'r-horizontal-rule--sub-primary': hrStyle === 'sub-primary',
      'r-horizontal-rule--gray': hrStyle === 'gray',
    }"
    noshade
  />
</template>

<script>
export default {
  name: "RHr",
  props: {
    hrStyle: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.r-horizontal-rule {
  background-color: #ffffff;
  --primary-color: #e87352;
  --sub-primary-color: #f8d6cd;
  --gray: #eaeaea;
  margin: 0;
  width: 100%;
}
.r-horizontal-rule--primary {
  border-top: 3px solid var(--primary-color);
  border-right: none;
  border-bottom: none;
  border-left: none;
}
.r-horizontal-rule--sub-primary {
  border-top: 3px solid var(--sub-primary-color);
  border-right: none;
  border-bottom: none;
  border-left: none;
}
.r-horizontal-rule--gray {
  border-top: 2px dotted var(--gray);
  border-right: none;
  border-bottom: none;
  border-left: none;
}
</style>
