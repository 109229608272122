import { auth, db, functions } from "@/libs";
import { signInWithEmailAndPassword } from "firebase/auth";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

export default {
  async testFunction() {
    try {
      const testFunctionCallable = httpsCallable(
        functions,
        "getAdminRecipeList",
      );
      await testFunctionCallable({
        id: "phj3BPg2aVArHAk2hld1",
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("ERROR", error);
      throw new Error(error);
    }
  },
  async signInCheck(username, password) {
    try {
      // ログイン処理
      await signInWithEmailAndPassword(
        auth,
        username + "@reci-pro.com",
        password,
      );

      const idTokenResult = await auth.currentUser.getIdTokenResult();
      const { claims } = idTokenResult;

      if (claims.role === "admin") {
        return true;
      }
    } catch (error) {
      throw new Error(error);
    }
    return false;
  },
  async isAdmin() {
    try {
      const idTokenResult = await auth.currentUser.getIdTokenResult();
      const { claims } = idTokenResult;
      if (!claims.role) return false;
      return claims.role === "admin";
    } catch (error) {
      // ToDo: Errorメッセージ
      throw new Error(error);
    }
  },
  getDisplayName() {
    return auth.currentUser.difisHeadquarterssplayName || "";
  },
  async getCreationDate() {
    try {
      const idTokenResult = await auth.currentUser.getIdTokenResult();
      const { claims } = idTokenResult;
      return claims.creationDate || "";
    } catch (error) {
      // ToDo: Errorメッセージ
      throw new Error(error);
    }
  },
  async getLastSignInDate() {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error("No user logged in");
      const lastSignInTime = user.metadata.lastSignInTime;
      const dt = new Date(lastSignInTime);
      const y = dt.getFullYear();
      const mon = ("00" + (dt.getMonth() + 1)).slice(-2);
      const d = ("00" + dt.getDate()).slice(-2);
      const h = ("00" + dt.getHours()).slice(-2);
      const m = ("00" + dt.getMinutes()).slice(-2);
      const s = ("00" + dt.getSeconds()).slice(-2);

      const strDate = `${y}/${mon}/${d} ${h}:${m}:${s}`;
      return strDate;
    } catch (error) {
      // ToDo: Errorメッセージ
      throw new Error(error);
    }
  },
  // 顧客名称の一覧を取得
  async getCustomerList() {
    try {
      // ToDo: orderbyを追加、権限チェック
      const querySnapshot = await getDocs(collection(db, "customers"));
      const records = { 顧客名称を選択してください: "" };
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        records[data.name] = doc.id;
      });
      return records;
    } catch (error) {
      // ToDo: Errorメッセージ
      throw new Error(error);
    }
  },
  // 店舗名称の一覧を取得
  async getStoreList(customerID) {
    try {
      // ToDo: orderbyを追加、権限チェック
      if (!customerID) throw new Error("Customer ID is required");

      const querySnapshot = await getDocs(
        collection(db, "customers", customerID, "stores"),
      );
      const records = { 店舗名称を選択してください: "" };
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        records[data.name] = doc.id;
      });

      return records;
    } catch (error) {
      throw new Error(error);
    }
  },
  // 店舗名称の一覧を取得
  async getStoreArrayList(customerID) {
    try {
      // ToDo: orderbyを追加、権限チェック
      const querySnapshot = await getDocs(
        collection(db, "customers", customerID, "stores"),
      );
      const records = [{ name: "すべて" }];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        records.push({ name: data.name });
      });

      return records;
    } catch (error) {
      throw new Error(error);
    }
  },
  async getCustomerName(customerID) {
    try {
      // 店舗名を取得
      const documentSnapshot = await getDoc(doc(db, "customers", customerID));
      return documentSnapshot.data() && documentSnapshot.data().name
        ? documentSnapshot.data().name
        : "";
    } catch (error) {
      throw new Error(error);
    }
  },
  async getStoreName(customerID, storeID) {
    try {
      let storeName = sessionStorage.storeName;
      if (storeName) {
        if (!customerID || !storeID) {
          throw new Error("Customer ID and Store ID are required");
        }
        // 店舗名を取得
        const documentSnapshot = await getDoc(
          doc(db, "customers", customerID, "stores", storeID),
        );
        if (documentSnapshot.exists()) {
          const recordData = documentSnapshot.data();

          storeName = recordData.name;
          sessionStorage.storeName = storeName;
          return storeName;
        } else {
          throw new Error("Store not found");
        }
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  async signOut() {
    try {
      await auth.signOut();
    } catch (error) {
      throw new Error(error);
    }
    return;
  },
};
