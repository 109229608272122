<template>
  <r-modal
    class="admin-editstore"
    :title="$t('admin.storeList.storeEdit')"
    cancelHidden
  >
    <r-spinner v-if="isShowSpinner" />
    <div v-else>
      <div class="admin-editstore--main" v-if="!confirm">
        <div class="admin-editstore--main--content">
          <r-heading :level="4">
            {{ $t("admin.storeList.storeName") }}
          </r-heading>
          <div class="wrapper-for-textbox">
            <r-textbox
              class="admin-createstore--main--content--item"
              type="string"
              v-model="storeName"
              name="storeName"
              :placeholder="isStoreNameInputed ? '' : '店舗名を入力'"
              :error="errorFlag.storeNameInvalid"
            />
          </div>
        </div>
        <div class="admin-editstore--main--content">
          <r-heading :level="4">
            {{ $t("admin.storeList.infomartUserName") }}
          </r-heading>
          <div class="wrapper-for-textbox">
            <r-textbox
              class="admin-createstore--main--content--item"
              type="string"
              v-model="infomartUser"
              name="infomartUser"
              :placeholder="isInfomartUserInputed ? '' : 'ユーザ名を入力'"
              :error="errorFlag.infomartUserInvalid"
            />
          </div>
        </div>
        <div class="admin-editstore--main--content">
          <r-heading :level="4"> InfomartFTPユーザパスワード </r-heading>
          <div class="wrapper-for-textbox">
            <r-textbox
              class="admin-createstore--main--content--item"
              type="string"
              v-model="infomartPassword"
              name="infomartPassword"
              :placeholder="isInfomartPasswordInputed ? '' : 'パスワードを入力'"
              :error="errorFlag.infomartPassInvalid"
            />
          </div>
        </div>
        <div class="admin-editstore--main--content">
          <r-heading :level="4">{{ $t("admin.storeList.pica1Cd") }}</r-heading>
          <div class="wrapper-for-textbox">
            <r-textbox
              class="admin-createstore--main--content--item"
              type="number"
              v-model.number="pica1Cd"
              name="pica1Cd"
              :placeholder="isPica1CdInputed ? '' : 'ぴかいちナビ店舗コード'"
              :error="errorFlag.pica1CdInvalid"
              :disabled="isSmaregiStoreIDInputed"
            />
          </div>
        </div>
        <div class="admin-editstore--main--content">
          <r-heading :level="4">{{
            $t("admin.storeList.smaregiStoreID")
          }}</r-heading>
          <div class="wrapper-for-textbox">
            <r-textbox
              class="admin-createstore--main--content--item"
              type="text"
              v-model="smaregiStoreID"
              name="smaregiStoreID"
              :placeholder="
                isSmaregiStoreIDInputed ? '' : 'スマレジ店舗IDを入力'
              "
              :disabled="isPica1CdInputed"
            />
          </div>
        </div>
        <div class="admin-editstore-btn-wrapper input-confirm-btn-wrapper">
          <r-button
            class="admin-editstore--main--content confirm-button"
            buttonStyle="primary"
            full
            @click="inputConfirm"
            >{{ $t("admin.userEditForm.inputConfirm") }}</r-button
          >
        </div>
      </div>

      <!-- 確認画面ここから -->
      <div class="admin-editstore--main" v-else>
        <div class="admin-editstore--main--content">
          <r-heading :level="4">
            {{ $t("admin.storeList.storeName") }}
          </r-heading>
          <div class="confirm-list-item">{{ storeName }}</div>
        </div>
        <div class="admin-editstore--main--content">
          <r-heading :level="4">
            {{ $t("admin.storeList.infomartUserName") }}
          </r-heading>
          <div v-if="isInfomartUserInputed" class="confirm-list-item">
            {{ infomartUser }}
          </div>
          <div v-else class="confirm-list-item">未入力</div>
        </div>
        <div class="admin-editstore--main--content">
          <r-heading :level="4">
            {{ $t("admin.storeList.infomartPassword") }}
          </r-heading>
          <div v-if="isInfomartPasswordInputed" class="confirm-list-item">
            {{ infomartPassword }}
          </div>
          <div v-else class="confirm-list-item">未入力</div>
        </div>
        <div class="admin-editstore--main--content">
          <r-heading :level="4">{{ $t("admin.storeList.pica1Cd") }}</r-heading>
          <div v-if="isPica1CdInputed" class="confirm-list-item">
            {{ pica1Cd }}
          </div>
          <div v-else class="confirm-list-item">未入力</div>
        </div>
        <div class="admin-editstore--main--content">
          <r-heading :level="4">{{
            $t("admin.storeList.smaregiStoreID")
          }}</r-heading>
          <div v-if="isSmaregiStoreIDInputed" class="confirm-list-item">
            {{ smaregiStoreID }}
          </div>
          <div v-else class="confirm-list-item">未入力</div>
        </div>
        <div class="admin-editstore--main--content admin-editstore-btn-wrapper">
          <r-button
            class="admin-editstore--main--content--button"
            buttonStyle="primary"
            full
            @click="regist"
            >{{ $t("admin.userEditForm.regist") }}</r-button
          >
          <r-button
            class="admin-editstore--main--content--button"
            buttonStyle="primary"
            full
            outline
            @click="inputCorrection"
            >{{ $t("admin.userEditForm.inputCorrection") }}</r-button
          >
        </div>
      </div>
    </div>
  </r-modal>
</template>
<script>
import RTextbox from "@/plugins/atoms/RTextbox.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import RModal from "@/plugins/molecules/RModal.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import auth from "@/libs/Auth.js";
import storeManager from "@/libs/Store.js";
import userManager from "@/libs/User.js";

export default {
  components: {
    RTextbox,
    RButton,
    RModal,
    RHeading,
    RSpinner,
  },
  props: {
    selectCustomerID: String,
    editStoreID: String,
  },
  data() {
    return {
      display: false,
      displayConfirm: false,
      confirm: false,
      id: "",
      storeName: "",
      infomartUser: "",
      infomartPassword: "",
      pica1Cd: "",
      smaregiStoreID: "",
      errorFlag: {
        storeNameInvalid: false,
        infomartUserInvalid: false,
        infomartPassInvalid: false,
      },
      isShowSpinner: false,
    };
  },
  methods: {
    inputConfirm() {
      // ToDo: 入力チェックを行う
      if (this.isStoreNameInputed) {
        //確認画面に転換
        this.confirm = true;
        //エラーflag初期化
        this.errorFlag.storeNameInvalid = false;
      } else {
        //店舗
        if (!this.isStoreNameInputed) {
          this.errorFlag.storeNameInvalid = true;
        } else {
          this.errorFlag.storeNameInvalid = false;
        }
        alert("入力漏れ、もしくは選択漏れがあります。");
      }
    },
    inputCorrection() {
      this.confirm = false;
    },
    async regist() {
      this.isShowSpinner = true;
      try {
        // 顧客情報をupdate
        const storeInfo = {
          id: this.editStoreID,
          customerID: this.selectCustomerID,
          storeName: this.storeName,
          infomartUser: this.infomartUser,
          infomartPassword: this.infomartPassword,
          pica1Cd: this.pica1Cd,
          smaregiStoreID: this.smaregiStoreID,
        };

        // ユーザ情報を取得
        await storeManager.updateStore(storeInfo);
        alert("店舗情報を更新しました。");
        this.$emit("close");
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("ERROR", error);
      }
      this.isShowSpinner = false;
    },
    generateRandomString(limit) {
      return userManager.generateRandomString(limit);
    },
  },
  computed: {
    isStoreNameInputed() {
      if (this.storeName) {
        return true;
      } else {
        return false;
      }
    },
    isInfomartUserInputed() {
      if (this.infomartUser) {
        return true;
      } else {
        return false;
      }
    },
    isInfomartPasswordInputed() {
      if (this.infomartPassword) {
        return true;
      } else {
        return false;
      }
    },
    isPica1CdInputed() {
      if (this.pica1Cd) {
        return true;
      } else {
        return false;
      }
    },
    isSmaregiStoreIDInputed() {
      if (this.smaregiStoreID) {
        return true;
      } else {
        return false;
      }
    },
  },
  async created() {
    this.isShowSpinner = true;
    try {
      const isAdmin = await auth.isAdmin();
      if (!isAdmin) {
        throw new Error("Forbidden");
      }

      // 店舗情報を取得
      const storeInfo = await storeManager.getStoreInfo(
        this.selectCustomerID,
        this.editStoreID,
      );
      // データをセット
      this.id = this.editStoreID;
      this.customerID = this.selectCustomerID;
      this.storeName = storeInfo.name;
      this.infomartUser = storeInfo.infomartUser;
      this.infomartPassword = storeInfo.infomartPassword;
      this.pica1Cd = storeInfo.pica1Cd;
      this.smaregiStoreID = storeInfo.smaregiStoreID;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    this.isShowSpinner = false;
  },
};
</script>

<style scoped>
.admin-editstore {
  height: 70vh !;
  padding: 20px;
}
.config-editstore--main {
  text-align: left;
}
.config-editstore--main--content--button {
  margin-top: 10px;
}
.config-editstore--main--content {
  margin-top: 20px;
}
.config-editstore--main--content--item {
  margin-top: 10px;
}
.admin-createstore--main--content--item {
  margin-bottom: 10px;
}
.wrapper-for-textbox {
  margin-left: 20px;
}
.admin-editstore-btn-wrapper {
  position: relative;
  bottom: -15vh;
  margin-bottom: 0px !important;
}
.input-confirm-btn-wrapper {
  position: unset;
  bottom: -10vh;
}
.confirm-button {
  margin-bottom: unset !important;
}
.admin-editstore--main--content--button {
  margin-bottom: 20px;
}
.admin-editstore--main--content--button:last-child {
  margin-bottom: unset;
}
.admin-editstore--main--content {
  margin-bottom: 20px;
}
.confirm-list-item {
  text-align: left;
  margin-left: 20px;
}
@media screen and (min-width: 641px) {
  .admin-editstore {
    min-height: 70vh;
    padding: 20px;
  }
  .config-editstore--main {
    text-align: left;
  }
  .config-editstore--main--content--button {
    margin-top: 10px;
  }
  .config-editstore--main--content {
    margin-top: 20px;
  }
  .config-editstore--main--content--item {
    margin-top: 10px;
  }
  .admin-createstore--main--content--item {
    width: 60%;
    margin-bottom: 10px;
  }
  .wrapper-for-textbox {
    margin-left: 20px;
  }
  .admin-editstore-btn-wrapper {
    position: relative;
    bottom: -20vh;
    margin-bottom: 0px !important;
  }
  .confirm-button {
    padding: 5px;
    margin-bottom: unset !important;
  }
  .admin-editstore--main--content--button {
    margin-bottom: 20px;
    padding: 5px;
  }
  .admin-editstore--main--content--button:last-child {
    margin-bottom: unset;
  }
  .admin-editstore--main--content {
    margin-bottom: 20px;
  }
  .confirm-list-item {
    text-align: left;
    margin-left: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .admin-editstore {
    max-height: 60vh;
    padding: 20px;
  }
  .admin-editstore-btn-wrapper {
    position: relative;
    bottom: -20vh;
    margin-bottom: 0px !important;
  }
}
</style>
<style module>
.datepickerwarapper {
  display: flex;
  justify-content: start;
  align-items: center;
}
.datepickerinput {
  border-radius: 4px;
  border: 2px solid #e6e6e6;
  padding: 8px;
  font-size: 1.2rem;
  width: 100%;
}
</style>
