import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./plugins/i18n/i18n.js";

// import "@babel/polyfill";

Vue.config.productionTip = false;

var store = {
  state: {
    customerID: "",
    customerName: "",
    storeID: "",
    storeName: "",
  },
  setCustomerInfoAction(id, name) {
    this.state.customerID = id;
    this.state.customerName = name;
  },
  setStoreInfoAction(id, name) {
    this.state.storeID = id;
    this.state.storeName = name;
  },
  clearCustomerInfoAction() {
    this.state.customerID = "";
    this.state.customerName = "";
  },
  clearStoreInfoAction() {
    this.state.storeID = "";
    this.state.storeName = "";
  },
};

new Vue({
  data: {
    privateState: store.state,
  },
  methods: {
    setCustomerInfoAction: function (id, name) {
      store.setCustomerInfoAction(id, name);
    },
    setStoreInfoAction: function (id, name) {
      store.setStoreInfoAction(id, name);
    },
    clearCustomerInfoAction: function () {
      store.clearCustomerInfoAction();
    },
    clearStoreInfoAction: function () {
      store.clearStoreInfoAction();
    },
    clearAllAction: function () {
      store.clearCustomerInfoAction();
      store.clearStoreInfoAction();
      sessionStorage.clear();
    },
  },
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
