import { functions } from "@/libs";
import { httpsCallable } from "firebase/functions";

export default {
  async updateSmaregi(customerID) {
    try {
      const bulkSmaregiUpdate = httpsCallable(functions, "bulkSmaregiUpdate");
      await bulkSmaregiUpdate({
        customerID: customerID || "",
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("ERROR", error);
      throw new Error(error);
    }
  },
};
