<template>
  <r-card class="r-modal" round>
    <!-- 見出し -->
    <h3 class="r-modal--heading">
      <span v-if="step" class="r-modal--heading--step">step{{ step }}</span>
      <span class="r-modal--heading--label">{{ title }}</span>
    </h3>
    <r-hr hrStyle="primary" />

    <!-- コンテンツ -->
    <div class="r-modal--content">
      <slot />
    </div>

    <!-- アクションボタン -->
    <div class="r-modal--action">
      <r-button
        v-if="enter"
        class="r-modal--action--button"
        buttonStyle="primary"
        full
        @click="clickHandler('enter')"
        >{{ enterLabel }}</r-button
      >
      <r-button
        v-if="skip"
        class="r-modal--action--button"
        buttonStyle="primary"
        outline
        full
        @click="clickHandler('skip')"
        >{{ $t("common.skip") }}</r-button
      >
      <r-button
        class="r-modal--action--button"
        buttonStyle="primary"
        outline
        full
        @click="clickHandler('cancel')"
        v-if="!cancelHidden"
        >{{ cancelLabel }}</r-button
      >
    </div>
  </r-card>
</template>

<script>
import RButton from "@/plugins/atoms/RButton.vue";
import RCard from "@/plugins/atoms/RCard.vue";
import RHr from "@/plugins/atoms/RHorizontalRule.vue";

export default {
  name: "r-modal",
  components: {
    RButton,
    RCard,
    RHr,
  },
  props: {
    step: Number,
    title: String,
    enter: Boolean,
    skip: Boolean,
    cancelHidden: Boolean,
    enterLabel: {
      type: String,
      default: "決定",
    },
    cancelLabel: {
      type: String,
      default: "キャンセル",
    },
  },
  methods: {
    clickHandler(action) {
      this.$emit(action);
    },
  },
};
</script>

<style scoped>
.r-modal {
  width: 90% !important;
  box-sizing: border-box;
  padding: 20px;
}
.r-modal--heading {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: left;
  padding-left: 20px;
  margin: 0;
}
.r-modal--heading--step {
  color: #e87352;
  margin-right: 10px;
}
.r-modal--content {
  margin-top: 20px;
}
.r-modal--action {
  margin-top: 20px;
  display: flex;
}
.r-modal--action--button {
  margin: 0 1%;
}
@media screen and (min-width: 641px) {
  .r-modal {
    width: 95vw !important;
  }
}
@media screen and (min-width: 1024px) {
  .r-modal {
    width: 1024px !important;
    box-sizing: border-box;
    padding: 20px;
  }
}
</style>
