<template>
  <r-paper round class="admin-createuser--main">
    <r-spinner v-if="isShowSpinner" />
    <r-modal-sub-header :title="$t('admin.userEditForm.createUser')" v-else>
      <div v-if="!confirm">
        <div class="admin-createuser--main--content content-wrapper">
          <r-heading :level="4"
            >{{ $t("admin.userEditForm.userID")
            }}<span class="required-mark">&nbsp;*</span></r-heading
          >
          <div class="admin-createuser--main-input-form">
            <div class="wrapper-for-textbox">
              <r-textbox
                class="admin-createuser--main--content--item"
                type="string"
                v-model="userID"
                name="userID"
                :placeholder="isUserIdInput ? '' : 'ユーザーIDを入力(生成)'"
                :error="errorFlag.userIdInvalid"
              />
            </div>
            <r-button
              class="admin-createuser--main--content--item id-create-btn"
              buttonStyle="secondary"
              full
              @click="generateUserID"
              >{{ $t("admin.userEditForm.generateUserID") }}</r-button
            >
          </div>
        </div>

        <div class="admin-createuser--main--content content-wrapper">
          <r-heading :level="4"
            >{{ $t("common.password") }}
            <span class="required-mark">&nbsp;*</span></r-heading
          >
          <div class="admin-createuser--main-input-form">
            <div class="wrapper-for-textbox">
              <r-textbox
                class="admin-createuser--main--content--item"
                type="password"
                v-model="password"
                name="password"
                v-if="!display"
                :placeholder="isPasswordInput ? '' : 'パスワードを入力'"
                :error="errorFlag.passwordInvalid"
              />
              <r-textbox
                class="admin-createuser--main--content--item"
                type="string"
                v-model="password"
                name="displayPassword"
                :placeholder="isPasswordInput ? '' : 'パスワードを入力'"
                v-if="display"
              />
            </div>
            <div class="admin-createuser--main--content button-wrapper-for-pw">
              <r-button
                class="admin-createuser--main--content--item pw-create-btn"
                buttonStyle="secondary"
                full
                type="password"
                @click="generatePassword"
                >{{ $t("admin.userEditForm.generatePassword") }}</r-button
              >
              <r-button
                class="admin-createuser--main--content--item pw-create-btn"
                buttonStyle="secondary"
                full
                @click="displayPassword"
                v-if="!display"
                >{{ $t("admin.userEditForm.displayPassword") }}</r-button
              >
              <r-button
                class="admin-createuser--main--content--item pw-create-btn"
                buttonStyle="secondary"
                full
                @click="hiddenPassword"
                v-if="display"
                >{{ $t("admin.userEditForm.hiddenPassword") }}</r-button
              >
            </div>
          </div>
        </div>

        <div class="admin-createuser--main--content content-wrapper">
          <r-heading :level="4"
            >{{ $t("admin.userEditForm.userName")
            }}<span class="required-mark">&nbsp;*</span></r-heading
          >
          <div class="wrapper-for-textbox">
            <r-textbox
              class="admin-createuser--main--content--item"
              type="string"
              v-model="displayName"
              name="displayName"
              :placeholder="isDisplayNameInput ? '' : 'ユーザー名を入力'"
              :error="errorFlag.displayNameInvalid"
            />
          </div>
        </div>
        <div class="admin-createcustomer--required-item-instruction">
          「<span class="required-mark">&nbsp;*&nbsp;</span>」は必須項目です。
        </div>
        <r-button
          class="admin-createuser--main--content admin-createuser-input-confirm"
          buttonStyle="primary"
          full
          @click="inputConfirm"
          >{{ $t("admin.userEditForm.inputConfirm") }}</r-button
        >
      </div>
      <!--確定画面-->
      <div v-else>
        <div class="admin-createuser-confirm-page">
          <dl class="admin-createuser-dl">
            <dt>
              {{ $t("admin.userEditForm.userID")
              }}<span class="required-mark">&nbsp;*</span>
            </dt>
            <dd>{{ userID }}</dd>
            <dt>
              {{ $t("common.password")
              }}<span class="required-mark">&nbsp;*</span>
            </dt>
            <dd>
              <div v-if="!displayConfirm">
                {{ maskPassword }}
                <r-button
                  buttonStyle="primary"
                  @click="displayConfirmPassword"
                  >{{ $t("admin.userEditForm.displayPassword") }}</r-button
                >
              </div>
              <div v-else>
                {{ password }}
                <r-button
                  buttonStyle="primary"
                  @click="hiddenConfirmPassword"
                  >{{ $t("admin.userEditForm.hiddenPassword") }}</r-button
                >
              </div>
            </dd>
            <dt>ユーザー名<span class="required-mark">&nbsp;*</span></dt>
            <dd>{{ displayName }}</dd>
          </dl>
        </div>
        <div class="btn-wrapper">
          <r-button
            buttonStyle="primary"
            full
            @click="inputCorrection"
            class="admin-createuser-btn admin-createuser-input-confirm"
            >{{ $t("admin.userEditForm.inputCorrection") }}</r-button
          >
          <r-button
            buttonStyle="primary"
            full
            @click="regist"
            class="admin-createuser-btn admin-createuser-input-confirm"
            >{{ $t("admin.userEditForm.regist") }}</r-button
          >
        </div>
      </div>
    </r-modal-sub-header>
  </r-paper>
</template>
<script>
import RTextbox from "@/plugins/atoms/RTextbox.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RModalSubHeader from "@/plugins/molecules/RModalSubHeader.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import auth from "@/libs/Auth.js";
import userManager from "@/libs/User.js";

export default {
  components: {
    RTextbox,
    RButton,
    RHeading,
    RPaper,
    RModalSubHeader,
    RSpinner,
  },
  data() {
    return {
      display: false,
      displayConfirm: false,
      confirm: false,
      userID: "",
      password: "",
      displayName: "",
      errorFlag: {
        userIdInvalid: false,
        passwordInvalid: false,
        displayNameInvalid: false,
      },
      isShowSpinner: false,
    };
  },
  computed: {
    maskPassword() {
      return "*".repeat(this.password.length);
    },
    isUserIdInput() {
      if (this.userID !== "") {
        return true;
      } else {
        return false;
      }
    },
    isPasswordInput() {
      if (this.password !== "") {
        return true;
      } else {
        return false;
      }
    },
    isDisplayNameInput() {
      if (this.displayName !== "") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    generateUserID() {
      this.userID = this.generateRandomString(8);
    },
    generatePassword() {
      this.password = this.generateRandomString(10);
    },
    displayPassword() {
      this.display = true;
    },
    hiddenPassword() {
      this.display = false;
    },
    displayConfirmPassword() {
      this.displayConfirm = true;
    },
    hiddenConfirmPassword() {
      this.displayConfirm = false;
    },
    inputConfirm() {
      if (
        this.isUserIdInput &&
        this.isPasswordInput &&
        this.isDisplayNameInput
      ) {
        //確認画面に転換
        this.confirm = true;
        //エラーflag初期化
        this.errorFlag.userIdInvalid = false;
        this.errorFlag.passwordInvalid = false;
        this.errorFlag.displayNameInvalid = false;
      } else {
        //顧客名称
        if (!this.isUserIdInput) {
          this.errorFlag.userIdInvalid = true;
        } else {
          this.errorFlag.userIdInvalid = false;
        }
        //使用期限
        if (!this.isPasswordInput) {
          this.errorFlag.passwordInvalid = true;
        } else {
          this.errorFlag.passwordInvalid = false;
        }
        //企業番号
        if (!this.isDisplayNameInput) {
          this.errorFlag.displayNameInvalid = true;
        } else {
          this.errorFlag.displayNameInvalid = false;
        }
        alert("入力漏れ、もしくは選択漏れがあります。");
      }
    },
    inputCorrection() {
      this.confirm = false;
    },
    async regist() {
      this.isShowSpinner = true;
      try {
        const userInfo = {
          userID: this.userID,
          password: this.password,
          displayName: this.displayName,
        };
        // ユーザ情報を取得
        await userManager.createAdminUser(userInfo);
        alert("Adminユーザー登録を完了しました。");
        //Top画面に遷移
        this.isShowSpinner = false;
        this.$emit("createUserComplete");
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("ERROR", error);
      }
    },
    generateRandomString(limit) {
      return userManager.generateRandomString(limit);
    },
  },
  async created() {
    this.isShowSpinner = true;
    try {
      const isAdmin = await auth.isAdmin();
      if (!isAdmin) {
        throw new Error("Forbidden");
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
    this.isShowSpinner = false;
  },
  beforeDestroy() {
    this.isShowSpinner = false;
  },
};
</script>

<style scoped>
.admin-createuser--main {
  padding: 20px;
}
.admin-createuser--main--content {
  margin-top: 20px;
}
.content-wrapper {
  margin-bottom: 20px;
}
.admin-createuser--main--content--item {
  margin-top: 10px;
}
.admin-createuser-confirm-page {
  margin-left: 20px;
}
.admin-createuser-dl {
  text-align: left;
}
dl dt {
  font-weight: bold;
  margin-bottom: 10px;
}
dl dd {
  margin-bottom: 20px;
}
.btn-wrapper {
  margin-top: 40px;
}
.admin-createuser-btn {
  margin-top: 20px;
}
.required-mark {
  font-size: 1.2rem;
  color: #e85951;
}
.admin-createcustomer--required-item-instruction {
  margin-top: 20px;
  text-align: left;
}
@media screen and (min-width: 641px) {
  .admin-createuser--main-input-form {
    width: 100%;
    display: flex;
  }
  .wrapper-for-textbox {
    width: 60%;
  }
  .id-create-btn {
    width: calc(20% - 20px);
    margin-left: 10px;
  }
  .button-wrapper {
    width: 40%;
  }
  .button-wrapper-for-pw {
    width: 40%;
    margin-top: unset;
  }
  .pw-create-btn {
    height: 43px;
    width: calc(50% - 20px);
  }
  .pw-create-btn:nth-of-type(2n-1) {
    margin-right: 20px;
  }
  .admin-createuser-input-confirm {
    padding: 5px;
  }
}
</style>
