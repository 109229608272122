<template>
  <footer class="r-footer">
    <small class="r-footer--small">&copy;{{ year }} recipro</small>
  </footer>
</template>

<script>
export default {
  computed: {
    year() {
      return "2019";
    },
    isLoginPage() {
      //ログイン時にheaderを隠す処理用
      if (this.$route.path === "/") {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      linkFullShow: true,
    };
  },
  methods: {
    handleResize() {
      if (window.innerWidth <= 680) {
        this.linkFullShow = false;
      } else {
        this.linkFullShow = true;
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.r-footer {
  color: #ffffff;
  background-color: #e45a34;
  width: 100%;
  box-sizing: border-box;
  font-weight: bold;
}
.r-footer--small {
  display: inline-block;
  background-color: #e24e26;
  width: 100%;
  margin: 0;
  padding: 10px 0;
}
</style>
