import { render, staticRenderFns } from "./AdminEditCustomerForm.vue?vue&type=template&id=246fdd40&scoped=true"
import script from "./AdminEditCustomerForm.vue?vue&type=script&lang=js"
export * from "./AdminEditCustomerForm.vue?vue&type=script&lang=js"
import style0 from "./AdminEditCustomerForm.vue?vue&type=style&index=0&id=246fdd40&prod&scoped=true&lang=css"
import style1 from "./AdminEditCustomerForm.vue?vue&type=style&index=1&id=246fdd40&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "246fdd40",
  null
  
)

export default component.exports