<template>
  <r-modal
    class="config-edituser"
    :title="
      $t('admin.userEditForm.user') +
      `「${userID}」` +
      $t('admin.userEditForm.userEdit')
    "
    cancelHidden
  >
    <r-spinner v-if="isShowSpinner" />
    <div v-else>
      <div class="admin-edituser--main" v-if="!confirm">
        <!--ユーザーID-->
        <div class="config-edituser--main--content">
          <r-heading :level="4">
            {{ $t("admin.userEditForm.userID") }}
          </r-heading>
          <div class="wrapper-for-textbox">
            <r-textbox
              type="string"
              v-model="userID"
              name="userID"
              :placeholder="isUserIdInput ? '' : 'ユーザーIDを入力'"
              :error="errorFlag.userIdInvalid"
            />
          </div>
        </div>
        <!--パスワード-->
        <div class="config-edituser--main--content">
          <r-heading :level="4">{{ $t("common.password") }}</r-heading>
          <div class="config-edituser-password-input">
            <div class="wrapper-for-textbox">
              <r-textbox
                class="config-edituser--main--content--item"
                type="password"
                v-model="password"
                name="password"
                v-if="!display"
                placeholder="パスワードを編集しない場合は空欄のままにしてください"
              />
              <r-textbox
                class="config-edituser--main--content--item"
                type="string"
                v-model="password"
                name="displayPassword"
                v-if="display"
                placeholder="パスワードを編集しない場合は空欄のままにしてください"
              />
            </div>
            <!--パスワード生成・表示toggle-->
            <div class="config-edituser--main--content button-wrapper">
              <r-button
                class="config-edituser--main--content--button pw-create-btn"
                buttonStyle="secondary"
                full
                type="password"
                @click="generatePassword"
                >{{ $t("admin.userEditForm.generatePassword") }}</r-button
              >
              <r-button
                class="config-edituser--main--content--button pw-create-btn"
                buttonStyle="secondary"
                full
                @click="displayPassword"
                v-if="!display"
                >{{ $t("admin.userEditForm.displayPassword") }}</r-button
              >
              <r-button
                class="config-edituser--main--content--button pw-create-btn"
                buttonStyle="secondary"
                full
                @click="hiddenPassword"
                v-if="display"
                >{{ $t("admin.userEditForm.hiddenPassword") }}</r-button
              >
            </div>
          </div>
        </div>
        <!--ユーザー名(displayName)-->
        <div class="config-edituser--main--content">
          <r-heading :level="4">
            {{ $t("admin.userEditForm.userName") }}
          </r-heading>
          <div class="wrapper-for-textbox">
            <r-textbox
              type="string"
              v-model="displayName"
              name="displayName"
              :placeholder="isDisplayNameInput ? '' : 'ユーザー名を入力'"
              :error="errorFlag.displayNameInvalid"
            />
          </div>
        </div>
        <!--確認ボタン-->
        <div class="config-edituser--main--content confirm-button-wrapper">
          <r-button
            class="config-edituser--main--content--button config-edituser--confirm-btn"
            buttonStyle="primary"
            full
            @click="inputConfirm"
            >{{ $t("admin.userEditForm.inputConfirm") }}</r-button
          >
        </div>
      </div>

      <!-- 確認画面ここから -->
      <div v-else>
        {{ $t("admin.userEditForm.inputEssentialItem") }}
        <div class="config-edituser-confirm-page">
          <dl class="config-edituser-dl">
            <dt>{{ $t("admin.userEditForm.userID") }} *</dt>
            <dd>{{ userID }}</dd>
            <dt>{{ $t("common.password") }}</dt>
            <dd>
              <div v-if="!displayConfirm">
                {{ maskPassword || passwordNotChange }}
                <r-button
                  buttonStyle="primary"
                  @click="displayConfirmPassword"
                  v-if="password"
                  >{{ $t("admin.userEditForm.displayPassword") }}</r-button
                >
              </div>
              <div v-else>
                {{ password || passwordNotChange }}
                <r-button
                  buttonStyle="primary"
                  @click="hiddenConfirmPassword"
                  v-if="password"
                  >{{ $t("admin.userEditForm.hiddenPassword") }}</r-button
                >
              </div>
            </dd>
            <dt>ユーザー名</dt>
            <dd>{{ displayName }}</dd>
          </dl>
        </div>
        <div
          class="config-edituser--main--content confirm-button-wrapper confirm-button-second"
        >
          <r-button
            class="config-edituser--main--content--button config-edituser--confirm-btn"
            buttonStyle="primary"
            full
            @click="regist"
            >{{ $t("admin.userEditForm.regist") }}</r-button
          >
          <r-button
            class="config-edituser--main--content--button config-edituser--confirm-btn"
            buttonStyle="primary"
            full
            outline
            @click="inputCorrection"
            >{{ $t("admin.userEditForm.inputCorrection") }}</r-button
          >
        </div>
      </div>
    </div>
  </r-modal>
</template>
<script>
import RTextbox from "@/plugins/atoms/RTextbox.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import RModal from "@/plugins/molecules/RModal.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import auth from "@/libs/Auth.js";
import userManager from "@/libs/User.js";

export default {
  components: {
    RTextbox,
    RButton,
    RModal,
    RHeading,
    RSpinner,
  },
  props: {
    editUserID: String,
  },
  data() {
    return {
      display: false,
      displayConfirm: false,
      confirm: false,
      id: "",
      userID: "",
      password: "",
      displayName: "",
      errorFlag: {
        userIdInvalid: false,
        passwordInvalid: false,
        displayNameInvalid: false,
      },
      isShowSpinner: false,
      passwordNotChange: "パスワード編集なし",
    };
  },
  computed: {
    maskPassword() {
      return "*".repeat(this.password.length);
    },
    isUserIdInput() {
      if (this.userID !== "") {
        return true;
      } else {
        return false;
      }
    },
    isPasswordInput() {
      if (this.password !== "") {
        return true;
      } else {
        return false;
      }
    },
    isDisplayNameInput() {
      if (this.displayName !== "") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    generatePassword() {
      this.password = this.generateRandomString(10);
    },
    displayPassword() {
      this.display = true;
    },
    hiddenPassword() {
      this.display = false;
    },
    displayConfirmPassword() {
      this.displayConfirm = true;
    },
    hiddenConfirmPassword() {
      this.displayConfirm = false;
    },
    inputConfirm() {
      if (
        this.isUserIdInput &&
        // this.isPasswordInput &&
        this.isDisplayNameInput
      ) {
        //確認画面に転換
        this.confirm = true;
        //エラーflag初期化
        this.errorFlag.userIdInvalid = false;
        this.errorFlag.passwordInvalid = false;
        this.errorFlag.displayNameInvalid = false;
      } else {
        //顧客名称
        if (!this.isUserIdInput) {
          this.errorFlag.userIdInvalid = true;
        } else {
          this.errorFlag.userIdInvalid = false;
        }
        //使用期限
        if (!this.isPasswordInput) {
          this.errorFlag.passwordInvalid = true;
        } else {
          this.errorFlag.passwordInvalid = false;
        }
        //企業番号
        if (!this.isDisplayNameInput) {
          this.errorFlag.displayNameInvalid = true;
        } else {
          this.errorFlag.displayNameInvalid = false;
        }
        alert("入力漏れ、もしくは選択漏れがあります。");
      }
    },
    inputCorrection() {
      this.confirm = false;
    },
    async regist() {
      this.isShowSpinner = true;
      try {
        // ユーザ情報をupdate
        const userInfo = {
          id: this.id,
          userID: this.userID,
          password: this.password,
          displayName: this.displayName,
        };
        // ユーザ情報を取得
        await userManager.updateAdminUser(userInfo);
        this.$emit("updateAdminUserComplete");
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("ERROR", error);
      }
    },
    generateRandomString(limit) {
      return userManager.generateRandomString(limit);
    },
  },
  async created() {
    this.isShowSpinner = true;
    try {
      const isAdmin = await auth.isAdmin();
      if (!isAdmin) {
        throw new Error("Forbidden");
      }

      // ユーザ情報を取得
      const userInfo = await userManager.getAdminUserInfo(this.editUserID);
      // データをセット
      this.id = userInfo.id;
      this.userID = userInfo.userID;
      this.displayName = userInfo.displayName;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    this.isShowSpinner = false;
  },
  beforeDestroy() {
    this.isShowSpinner = false;
  },
};
</script>

<style scoped>
.config-edituser {
  padding: 20px;
  max-height: 90vh;
}
.config-edituser--main {
  text-align: left;
}
.config-edituser--main--content--button {
  margin-top: 10px;
}
.config-edituser--main--content {
  margin-top: 10px;
}
.config-edituser--main--content--item {
  margin-top: 10px;
  margin-bottom: 20px;
}
.wrapper-for-textbox {
  margin-left: 20px;
}
.button-wrapper {
  margin-top: 0px;
}
.config-edituser-confirm-page {
  margin-left: 20px;
}
.config-edituser-dl {
  text-align: left;
}
dl dt {
  font-weight: bold;
  margin-bottom: 10px;
}
dl dd {
  margin-bottom: 20px;
}
.confirm-button-wrapper {
  position: relative;
  bottom: -15vh;
}

@media screen and (min-width: 641px) {
  .config-edituser {
    padding: 20px;
    min-height: 70vh;
    max-height: 70vh;
  }
  .config-edituser--main {
    text-align: left;
  }
  .config-edituser--main--content--button {
    margin-top: 10px;
  }
  .config-edituser--main--content {
    margin-top: 20px;
  }
  .config-edituser--main--content--item {
    margin-top: unset;
    margin-bottom: unset;
  }
  .wrapper-for-textbox {
    margin-left: 20px;
    margin-top: unset;
    margin-bottom: unset;
    width: 60%;
    display: flex;
    align-items: flex-end;
  }
  .config-edituser-password-input {
    display: flex;
    align-items: flex-end;
  }
  .button-wrapper {
    margin-top: 0px;
    width: calc(40% - 20px);
    display: flex;
    align-items: flex-end;
  }
  .pw-create-btn {
    width: calc(50% - 20px);
    height: 43px;
    padding: 5px;
  }
  .pw-create-btn:nth-of-type(2n-1) {
    margin-right: 20px;
  }
  .confirm-button {
    margin-top: 60px;
  }
  .config-edituser-confirm-page {
    margin-left: 20px;
  }
  .config-edituser-dl {
    text-align: left;
  }
  dl dt {
    font-weight: bold;
    margin-bottom: 10px;
  }
  dl dd {
    margin-bottom: 20px;
  }
  .confirm-button-wrapper {
    position: relative;
    bottom: -22vh;
  }
  .confirm-button-second {
    bottom: -25vh;
  }
  .config-edituser--confirm-btn {
    padding: 5px;
  }
}
@media screen and (min-width: 1024px) {
  .config-edituser {
    padding: 20px;
  }
  .confirm-button-wrapper {
    position: relative;
    bottom: -30vh;
  }
  .confirm-button-second {
    bottom: -28vh;
  }
  .confirm-button {
    margin-top: 50px;
  }
}
</style>
