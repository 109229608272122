<template>
  <div class="r-list-item">
    <p class="r-list-item--content">
      <slot></slot>
    </p>
    <p class="r-list-item--caption">{{ caption }}</p>
  </div>
</template>

<script>
export default {
  name: "r-list-item",
  props: {
    caption: String,
  },
};
</script>

<style scoped>
.r-list-item {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
.r-list-item--content,
.r-list-item--caption {
  padding: 0;
  margin: 0;
}
.r-list-item--caption {
  font-size: 0.8rem;
  color: #848484;
}
</style>
