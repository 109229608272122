import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const data = require("./sentense.json");

let locale =
  (window.navigator.languages && window.navigator.languages[0]) ||
  window.navigator.language ||
  window.navigator.userLanguage ||
  window.navigator.browserLanguage;

//日本語および英語対応
if (locale == "ja" || locale == "ja-JP") {
  locale = "ja";
} else {
  locale = "ja";
  // locale = 'en'; // Sentence.json only contains Japanese language
}

const i18n = new VueI18n({
  locale: locale,
  messages: data,
});

export default i18n;
