<template>
  <div class="r-textbox">
    <label for="$attrs.name" class="r-textbox--label">{{ label }}</label>
    <div class="r-textbox--inner-wrapper">
      <input
        v-bind="$attrs"
        :type="$attrs.type ? $attrs.type : 'text'"
        :name="$attrs.name"
        :disabled="disabled"
        class="r-textbox--input"
        :class="{
          disabled: disabled,
          error: error,
        }"
        @input="onInput"
      />
      <span class="r-textbox-unit">{{ unit }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "r-textbox",
  props: {
    label: {
      type: String,
      default: "",
    },
    unit: {
      type: String,
      default: "",
    },
    disabled: Boolean,
    error: Boolean,
  },
  methods: {
    onInput(e) {
      this.$emit("input", e.target.value);
    },
  },
};
</script>

<style scoped>
.r-textbox {
  --disable-color: #ebebe4;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.r-textbox--inner-wrapper {
  display: flex;
  justify-content: start;
  align-items: center;
}
.r-textbox--label {
  font-weight: bold;
  text-align: left;
}
.r-textbox--input {
  border-radius: 4px;
  border: 2px solid #e6e6e6;
  padding: 8px;
  font-size: 1.2rem;
  width: 100%;
}
.r-textbox-unit {
  margin-left: 5px;
}
.disabled {
  background-color: var(--disable-color);
}
.error {
  border: 2px solid #e85951;
}
</style>
