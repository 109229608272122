<template>
  <r-paper class="admin-category" round>
    <r-spinner v-if="isShowSpinner" />
    <div v-else>
      <r-modal-sub-header :title="$t('admin.userEditForm.editDeleteUser')" />
      <!--ユーザー選択領域-->
      <span>{{ $t("admin.userEditForm.editDeleteUserExplan") }}</span>
      <div class="admin-category-store-select-wrapper">
        <div
          class="config-category--main select-store-wrapper"
          :class="errorFlag ? ' store-not-selected' : ''"
        >
          <r-checkbox :items="users" @toggle="toggle" />
        </div>
        <div class="admin-category--button-wrapper">
          <r-button
            class="admin-category--button"
            buttonStyle="secondary"
            full
            @click="selectUser"
            >{{ $t("admin.userEditForm.editUser") }}</r-button
          >
          <r-button
            class="admin-category--button"
            buttonStyle="secondary"
            full
            @click="deleteUser"
            >{{ $t("admin.userEditForm.deleteUser") }}</r-button
          >
        </div>
      </div>
    </div>
  </r-paper>
</template>
<script>
import RButton from "@/plugins/atoms/RButton.vue";
import RCheckbox from "@/plugins/atoms/RCheckbox.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RModalSubHeader from "@/plugins/molecules/RModalSubHeader.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import auth from "@/libs/Auth.js";
import userManager from "@/libs/User.js";

export default {
  components: {
    RButton,
    RCheckbox,
    RPaper,
    RModalSubHeader,
    RSpinner,
  },
  data() {
    return {
      customerID: "",
      storeID: "",
      users: [],
      inputName: "",
      isShowSpinner: false,
      errorFlag: false,
    };
  },
  computed: {},
  methods: {
    toggle(index) {
      this.$set(this.users[index], "bool", !this.users[index].bool);
    },
    selectUser() {
      // 複数選択されていた場合、メッセージを表示する
      if (this.users.filter((e) => e.bool).length >= 2) {
        alert(this.$t("admin.userEditForm.selectOneUserInCaseOfEdit"));
        this.errorFlag = true;
        return;
      }
      if (this.users.filter((e) => e.bool).length <= 0) {
        //入力チェック:顧客及び店舗の選択
        this.errorFlag = true;
        alert(
          "編集するユーザーが選択されていません。ユーザーの選択を行ってください。",
        );
        return;
      }
      const index = this.users.map((e) => e.bool).indexOf(true);
      //編集に切り替える
      this.$emit("edit", this.users[index].id);
      //エラーflagの初期化
      this.errorFlag = false;
    },
    async deleteUser() {
      if (
        window.confirm("顧客のアカウント情報を削除します。よろしいですか？")
      ) {
        if (this.users.filter((e) => e.bool).length <= 0) {
          //入力チェック:顧客及び店舗の選択
          this.errorFlag = true;
          alert(
            "削除するユーザーが選択されていません。ユーザーの選択を行ってください。",
          );
          return;
        }
        this.isShowSpinner = true;
        try {
          await Promise.all(
            this.users.map(async (user) => {
              if (user.bool) {
                await userManager.deleteAdminUser(user.id);
              }
            }),
          );
          //現在のページをreload
          this.$router.go({
            path: this.$router.currentRoute.path,
            force: true,
          });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          throw new Error(error);
        }
        alert("削除完了");
      }
    },
  },
  async created() {
    this.isShowSpinner = true;
    try {
      const isAdmin = await auth.isAdmin();
      if (!isAdmin) {
        throw new Error("Forbidden");
      }

      // ユーザ一覧を取得
      const userList = await userManager.getAdminUserList();
      this.users = userList.map((user) => {
        const recoed = { id: user.id, name: user.displayName, bool: false };
        return recoed;
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    this.isShowSpinner = false;
  },
  beforeDestroy() {
    this.isShowSpinner = false;
  },
};
</script>

<style scoped>
.admin-category {
  padding: 20px;
}
.config-category--main {
  text-align: left;
  border: 2px solid #e6e6e6;
  border-radius: 5px;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  min-height: 25vh;
}
.select-store-wrapper {
  overflow: auto;
}
.config-category--button {
  margin-top: 10px;
}
.admin-category--button-wrapper {
  margin-top: 20px;
}
.admin-category--button {
  margin-top: 20px;
}
.store-not-selected {
  border: 2px solid #e85951;
}
@media screen and (min-width: 641px) {
  .admin-category-store-select-wrapper {
    margin-top: 10px;
    display: grid;
    vertical-align: middle;
    grid-template-columns: 5fr 1fr;
    gap: 10px;
    min-height: 300px;
  }
  .select-store-wrapper {
    grid-template-columns: 5fr;
  }
  .admin-category--button-wrapper {
    grid-template-columns: 1fr;
    margin: auto;
    display: block;
  }
  .admin-category--button {
    padding: 5px;
  }
}
</style>
