<template>
  <r-paper class="admin-master" round>
    <admin-upload-master-form
      v-if="isShow"
      :customerID="customerID"
      :storeID="storeID"
      :csv="csvData"
    />
  </r-paper>
</template>
<script>
import RPaper from "@/plugins/atoms/RPaper.vue";
import AdminUploadMasterForm from "@/components/config/organisms/AdminUploadMasterForm.vue";

import auth from "@/libs/Auth.js";
import Encoding from "encoding-japanese";
import Papa from "papaparse";

export default {
  components: {
    RPaper,
    AdminUploadMasterForm,
  },
  props: {
    selectCustomerID: {
      type: String,
      required: true,
    },
    editStoreID: {
      type: String,
      required: true,
    },
    csv: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      customerID: "",
      storeID: "",
      csvData: [],
      isShow: false,
    };
  },
  async created() {
    this.isShowSpinner = true;
    try {
      const isAdmin = await auth.isAdmin();
      if (!isAdmin) {
        throw new Error("Forbidden");
      }

      if (this.selectCustomerID && this.editStoreID && this.csv) {
        this.customerID = this.selectCustomerID;
        this.storeID = this.editStoreID;
        const csvArray = this.csv.split(",");

        const csvInfo = csvArray[0];
        const csvBase64 = csvArray[1];

        // if (csvInfo.indexOf("csv") == -1 || csvInfo.indexOf("base64") == -1) {
        if (csvInfo.indexOf("base64") == -1) {
          // csvデータではないエラー
          throw new Error("not csv file");
        }

        // Base64をdecode
        const decodeStr = atob(csvBase64);

        // ShiftJISからUTF-8
        const strConv = Encoding.convert(decodeStr, {
          from: "SJIS",
          to: "UNICODE",
          type: "string",
        });

        // ヘッダの項目が重複しているのでヘッダ情報を置き換え
        // ヘッダ行入れ替え
        const endPos = strConv.indexOf("\n", 0);
        const outStr = strConv.substr(endPos + 1);
        const strHeader =
          '"［マイカタログID］","［食品小分類コード］","［食品大分類名］","［食品中分類名］","［食品小分類名］","［商品システムコード］","［商品名］","［規格］","［入数単位］","［単価］","［旧単価］","［取引先名］","［単価変更日］","［自社管理入数］","［自社管理入数単位］","［発注単価］","［発注単位］","［マイカタログ単価］","［マイカタログ旧単価］","［マイカタログ単位］","［マイカタログ変更日］","［食品大分類コード］","［食品中分類コード］","［取引先名カナ］","［商品名カナ］","［入数］"\n';
        const transData = strHeader + outStr;

        // CSVからJSONに変換
        const parsedData = Papa.parse(transData, {
          header: true,
          skipEmptyLines: true,
        });

        this.csvData = parsedData.data;
        this.isShow = true;
      } else {
        throw new Error("Forbidden");
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    this.isShowSpinner = false;
  },
  beforeDestroy() {
    this.isShowSpinner = false;
  },
};
</script>

<style scoped>
.admin-master {
  padding: 20px;
}
</style>
