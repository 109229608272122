<template>
  <div>
    <header
      class="r-header"
      v-if="!isLoginPage"
      :class="pageConfirm === 'admin' ? 'r-header-white-bg' : ''"
    >
      <!-- ヘッダーメインここから -->
      <div class="r-header--content">
        <div class="r-header-content--left">
          <div class="r-header--content--buttons--home-alter-logo-img" />
        </div>

        <div class="r-header-content--buttons--icon-buttons">
          <button
            class="r-header--content--buttons--button r-header--content--buttons--sub-menu"
            :class="pageConfirm === 'admin' ? 'sub-menu--alt' : ''"
            @click="toggleisShowSubMenu"
          >
            <i class="fas fa-ellipsis-v"></i>
          </button>
        </div>
      </div>
      <!-- ヘッダーメインここまで -->

      <div
        class="menu--bg"
        v-if="isShowMenu"
        @click.self="toggleisShowMenu"
      ></div>

      <r-card round class="sub-menu" v-if="isShowSubMenu">
        <r-list>
          <r-list-content>
            <r-list-item class="sub-menu--user">
              <div class="sub-menu--user--login">
                <div>{{ $t("design.previousLogin") }}</div>
                <div>{{ lastLoginDate }}</div>
              </div>
            </r-list-item>
          </r-list-content>
          <r-list-content clickable @click="logout">
            <r-list-item>
              <img
                class="header--submenu--icon"
                :src="logoutImage"
                alt="ログアウト"
              />
              {{ $t("common.logout") }}
            </r-list-item>
          </r-list-content>
        </r-list>
      </r-card>
      <div
        class="menu--bg"
        v-if="isShowSubMenu"
        @click.self="toggleisShowSubMenu"
      ></div>
    </header>
  </div>
</template>

<script>
import RList from "@/plugins/atoms/RList.vue";
import RListContent from "@/plugins/atoms/RListContent.vue";
import RListItem from "@/plugins/atoms/RListItem.vue";
import RCard from "@/plugins/atoms/RCard.vue";

import auth from "@/libs/Auth.js";

export default {
  components: {
    RList,
    RListContent,
    RListItem,
    RCard,
  },
  data() {
    return {
      isShowMenu: false,
      isShowSubMenu: false,
      customerID: "",
      storeID: "",
      lastLoginDate: "",
      width: window.innerWidth,
      logoutImage: require("../../assets/images/logout.png"),
    };
  },
  methods: {
    toggleisShowMenu() {
      this.isShowMenu = !this.isShowMenu;
    },
    toggleisShowSubMenu() {
      this.isShowSubMenu = !this.isShowSubMenu;
    },
    toLink(name) {
      this.$router.push({ name: name });
      this.isShowMenu = false;
    },
    async logout() {
      try {
        await auth.signOut();
        // ローカルデータ削除処理
        await this.$root.clearAllAction();
        this.$router.push({ name: "logout" });
      } catch (error) {
        throw new Error(error);
      }
    },
  },
  computed: {
    isLoginPage() {
      //ログイン時にheaderを隠す処理用
      if (this.$route.path === "/" || this.$route.path === "/logout") {
        return true;
      }
      return false;
    },
    pageConfirm() {
      if (this.$route.path === "/admin") {
        return "admin";
      } else {
        return "";
      }
    },
  },
  beforeDestroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  async created() {
    try {
      this.lastLoginDate = await auth.getLastSignInDate();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
  },
};
</script>

<style scoped>
/**
  r-header
 */
.r-header {
  background-color: #e87352;
  width: 100%;
  height: 16vh;
  position: relative;
  box-sizing: border-box;
  padding: 10px 0;
}
.r-header-white-bg {
  background-color: #fff;
  width: 100%;
  height: 16vh;
  position: relative;
  box-sizing: border-box;
  padding: 10px 0;
}
.r-header--content--buttons--home-alter-logo-img {
  width: 100px;
  height: 56px;
  background-image: url(~@/assets/images/logo4.png);
  background-size: 100px 56px;
}
.r-header--content--company-name {
  color: #ffffff;
  font-weight: bold;
  font-size: 1.2rem;
}
.r-header-white-bg-company-name {
  color: #444;
  font-weight: bold;
  font-size: 1.2rem;
}
/** r-header--sub-header */
.r-header--sub-header {
  width: 100%;
  height: 36px;
  background-color: #e24f25;
  text-align: left;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px 10px;
  box-sizing: border-box;
}
.r-header--sub-header--title {
  color: #ffffff;
  font-weight: bold;
}

/** r-header--main */
.r-header--content {
  display: flex;
  justify-content: space-between;
  height: calc(14vh - 36px);
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
}
.r-header-content--left {
  display: flex;
  justify-items: center;
  align-items: center;
  color: #ffffff;
}

.r-header--content--buttons--button {
  font-size: 2rem;
  color: #ffffff;
  border: none;
  background-color: transparent;
  appearance: none;
}
.r-header-content--buttons--icon-buttons {
  display: flex;
}
.r-header--content--buttons--icon-button {
  border: none;
  height: 100%;
  box-sizing: border-box;
  background-color: #e87352;
  background-repeat: no-repeat;
  background-size: auto 22px;
  background-position: center 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.white-bg {
  border: none;
  height: 100%;
  box-sizing: border-box;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: auto 22px;
  background-position: center 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.r-header--content--buttons--button:hover {
  cursor: pointer;
  background-color: #fc9679;
  transition: all 0.5s;
}
.selected {
  cursor: pointer;
  background-color: #fc9679;
}
.r-header--content--buttons--button--hide {
  background-color: #e87352;
}
.r-header--content--buttons--badge {
  position: absolute;
  right: -10px;
  top: -10px;
  font-weight: bold;
}
.r-header--content--buttons--icon-button:hover {
  cursor: pointer;
  background-color: #fc9679;
  transition: all 0.5s;
}
.r-header--content--buttons--icon-button--label {
  color: #ffffff;
  font-weight: bold;
  font-size: 0.8rem;
}
.button--label-white-bg {
  color: #e87352;
  font-weight: bold;
  font-size: 0.8rem;
}
.btn-disable {
  pointer-events: none;
  opacity: 0.5;
}
/** r-header--menu */
.menu-content {
  border-top: 2px solid #ffffff;
  position: absolute;
  z-index: 15;
  top: 60px;
  width: 100%;
  transition: height 0.4s linear;
}
.menu-content--list {
  background-color: #e87352;
}
.menu-content--item {
  color: #ffffff;
  padding: 10px 10px;
  font-weight: bold;
  box-sizing: border-box;
}

/** r-header-menu animation transition */
.menu-enter-active {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: menu--open;
}
.menu-leave-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: menu--close;
}
@keyframes menu--open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes menu--close {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/** r-header--sub-menu */
.r-header--content--buttons--sub-menu {
  position: relative;
}
.sub-menu--alt {
  color: #e87352;
}
.sub-menu--alt:hover {
  background: #fff;
  color: #fc9679;
  transition: 0.5s;
}
.sub-menu {
  position: absolute;
  width: 50%;
  z-index: 15;
  right: 10px;
  padding: 5px 10px;
}
.menu--bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  min-height: 100vh;
  width: 100vw;
  z-index: 5;
}
.sub-menu--user--login {
  box-sizing: border-box;
  border-left: 2px solid #e87352;
  padding-left: 10px;
}
.header--submenu--icon {
  width: 20px;
  height: auto;
  margin-right: 5px;
}

.login-header {
  width: 100%;
  height: 14vh;
  text-align: center;
  position: relative;
  box-sizing: border-box;
  padding: 10px;
  background-color: #fff;
  vertical-align: middle;
}
.logo-image {
  width: 100px;
  height: 56px;
}
/** PC・タブレット */
@media screen and (min-width: 641px) {
  .r-header {
    height: 10vh;
  }
  .r-header--content {
    width: 100%;
    margin: auto;
    height: 100%;
  }
  .r-header--content--buttons--icon-button {
    background-size: auto 32px;
    background-position: center 20%;
    width: 80px;
  }
  .white-bg {
    background-size: auto 32px;
    background-position: center 20%;
    width: 80px;
  }
  .white-bg:hover {
    background-color: #fff;
    opacity: 0.7;
    transition: 0.5s;
  }
  .r-header--content--buttons--icon-button--label {
    color: #ffffff;
    font-weight: bold;
    font-size: 0.6rem;
    width: 80px;
  }
  .button--label-white-bg {
    color: #e87352;
    font-weight: bold;
    font-size: 0.6rem;
    width: 80px;
  }
  .r-header--content--buttons--badge {
    top: 5px;
  }
}

/** PC */
@media (min-width: 1024px) {
  .r-header {
    width: 100%;
    height: 12vh;
  }
  .r-header--content {
    width: 1024px;
    margin: auto;
    height: 100%;
  }
  .r-header--content--buttons--icon-button {
    background-size: auto 42px;
    background-position: center 20%;
  }
  .white-bg {
    background-size: auto 42px;
    background-position: center 20%;
  }
  /*ログインヘッダー*/
  .login-header {
    height: 8vh;
  }
  .r-header--content--buttons--icon-button--label {
    color: #ffffff;
    font-weight: bold;
    font-size: 0.6rem;
  }
  .button--label-white-bg {
    color: #e87352;
    font-weight: bold;
    font-size: 0.6rem;
  }
  .r-header--content--buttons--badge {
    top: 10px;
  }
}
</style>
