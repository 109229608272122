<template>
  <div
    v-if="!image"
    class="r-fileupload"
    :class="[{ 'r-fileupload--drag': isDrag === 'new' }]"
    @dragover.prevent="checkDrag($event, 'new', true)"
    @dragleave.prevent="checkDrag($event, 'new', false)"
    @drop.prevent="onFileChange"
  >
    <span v-if="error" class="csv-file-error-msg">{{ error }}</span>
    <label class="r-fileupload--label" for="file">
      <i :class="['fa', 'fa-' + icon]" v-if="icon"></i>
      <slot></slot>
      <input
        id="file"
        class="r-fileupload--input"
        type="file"
        @change="onFileChange"
      />
    </label>
  </div>
  <img class="preview-item-file" v-else :src="image" alt />
</template>

<script>
import Encoding from "encoding-japanese";
// import CsvSync from "csv-parse/lib/sync";

export default {
  name: "r-fileupload",
  props: {
    icon: {
      type: String,
      default: "camera",
    },
    image: String,
  },
  data() {
    return {
      uploadedCSV: "",
      isDrag: null,
      error: "",
      masterColumnCount: 26,
    };
  },
  methods: {
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      const fileTypes = files[0].name.split(".");
      const len = fileTypes.length;
      if (len === 0) {
        return;
      }
      const extension = fileTypes[len - 1];
      const lowerExtension = extension.toLowerCase();
      if (lowerExtension === "csv") {
        this.createCSV(files[0]);
      } else {
        this.error = "拡張子が不正です。";
        return;
      }
    },
    createCSV(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.uploadedCSV = e.target.result;
        // ファイルupload時にmaster.csv形式ではないcsvファイルをチェック
        const result = this.checkCSVFile(this.uploadedCSV);
        if (!result) {
          alert(
            "不正なデータが含まれています。csvファイルを確認してください。",
          );
          return;
        }
        this.$emit("change", this.uploadedCSV);
      };
      reader.readAsDataURL(file);
    },
    checkCSVFile(csvFile) {
      let result = false;
      if (csvFile) {
        const csvArray = csvFile.split(",");
        const csvInfo = csvArray[0];
        const csvBase64 = csvArray[1];
        //        if (csvInfo.indexOf("csv") == -1 || csvInfo.indexOf("base64") == -1) {
        if (csvInfo.indexOf("base64") == -1) {
          // csvデータではないエラー
          throw new Error("not csv file");
        }
        // Base64をdecode
        const decodeStr = atob(csvBase64);
        // ShiftJISからUTF-8
        const strConv = Encoding.convert(decodeStr, {
          from: "SJIS",
          to: "UNICODE",
          type: "string",
        });
        //csvファイルを配列化
        let arr = strConv.split("\n");
        arr.forEach((e) => {
          const innerArr = e.split(",");
          if (innerArr.length != this.masterColumnCount) {
            return;
          } else {
            //columnが26項目の場合
            result = true;
          }
        });
      }
      return result;
    },
    checkDrag(event, key, status) {
      if (status && event.dataTransfer.types === "text/csv") {
        return false;
      }
      this.isDrag = status ? key : null;
    },
  },
};
</script>

<style scoped>
.r-fileupload {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #f8d5cb;
  padding: 80px 20px;
  min-height: 20vh;
}
.r-fileupload--drag {
  border: 1px solid #e87352;
  /**TODO 色の変更*/
  background-color: rgba(0, 0, 0, 0.3);
}
.r-fileupload--label {
  background-color: #e87352;
  color: #ffffff;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 25px;
}
.r-fileupload--label:hover {
  cursor: pointer;
  background-color: #e24f25;
  transition: 0.5s;
}
.r-fileupload--input {
  display: none;
}
.preview-item-file {
  width: 100%;
}
.csv-file-error-msg {
  height: 10px;
  margin: 0 0 10px 0;
  color: #e85951;
  font-weight: bold;
}
@media screen and (min-width: 641px) {
  .preview-item-file {
    width: 60%;
  }
}
@media screen and (min-width: 1024px) {
  .preview-item-file {
    width: 40%;
  }
}
</style>
