<template>
  <select
    @change="updateValue"
    v-model="selected"
    class="r-select"
    :class="{ error: error }"
  >
    <option v-if="label" value hidden>{{ label }}</option>
    <option v-for="(option, index) in options" :key="index" :value="option">
      {{ index }}
    </option>
  </select>
</template>

<script>
export default {
  name: "r-select",
  props: {
    options: Object,
    label: String,
    existingValue: {
      type: String,
      default: "",
    },
    error: Boolean,
  },
  data() {
    return {
      selected: "",
    };
  },
  methods: {
    updateValue(e) {
      this.$emit("input", e.target.value);
    },
  },
  mounted() {
    if (this.existingValue === "") {
      this.$emit("input", "");
    } else {
      this.selected = this.existingValue;
      this.$emit("input", this.existingValue);
    }
  },
};
</script>

<style scoped>
.r-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #517ce8;
  width: 100%;
  border: none;
  padding-left: 5px;
  font-size: 1.2rem;
  color: #ffffff;
}
.error {
  background-color: #e85951;
  border: none;
}
</style>
