<template>
  <r-paper round class="admin-createcustomer--main">
    <r-spinner v-if="isShowSpinner" />
    <r-modal-sub-header
      :title="$t('admin.customerList.customerRegistration')"
      v-else
    >
      <div v-if="!confirm" class="admin-createcustomer-content">
        <div class="admin-createcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.customerName")
            }}<span class="required-mark">&nbsp;*</span>
          </r-heading>
          <div class="wrapper-for-textbox">
            <r-textbox
              class="admin-createcustomer--main--content--item text-box"
              type="string"
              v-model="customerName"
              :placeholder="isCustomerNameInput ? '' : '顧客名称を入力'"
              name="customerName"
              :error="errorFlag.nameInvalid"
            />
          </div>
        </div>
        <div class="admin-createcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.expireDate")
            }}<span class="required-mark">&nbsp;*</span>
          </r-heading>
          <Datepicker
            :language="ja"
            :format="DatePickerFormat"
            :wrapper-class="$style.datepickerwrapper"
            :input-class="[
              $style.datepickerinput,
              errorFlag.dateInvalid ? $style.invalid : '',
            ]"
            :placeholder="isExpireDateSelected ? '' : '使用期限を選択'"
            v-model="expireDate"
            name="expireDate"
            :error="errorFlag.dateInvalid"
          />
        </div>
        <div class="admin-createcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.corpNo") }}
          </r-heading>
          <div class="wrapper-for-textbox">
            <r-textbox
              class="admin-createcustomer--main--content--item text-box"
              type="string"
              v-model="corpNo"
              name="corpNo"
              :placeholder="isCorpNoInput ? '' : 'ぴかいちナビ企業番号を入力'"
              :disabled="!canCorpNoInput"
            />
            <!-- :error="errorFlag.corpNoInvalid" -->
          </div>
        </div>

        <div class="admin-createcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.smaregiContractID")
            }}<span v-if="isSmaregiAnyInput" class="required-mark"
              >&nbsp;*</span
            >
          </r-heading>
          <div class="wrapper-for-textbox">
            <r-textbox
              class="admin-createcustomer--main--content--item text-box"
              type="string"
              v-model="smaregiContractID"
              name="smaregiContractID"
              :placeholder="'スマレジ契約IDを入力'"
              :disabled="!canSmaregiInput"
              :error="errorFlag.smaregiContractIDInvalid"
            />
          </div>
        </div>

        <div class="admin-createcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.smaregiClientID")
            }}<span v-if="isSmaregiAnyInput" class="required-mark"
              >&nbsp;*</span
            >
          </r-heading>
          <div class="wrapper-for-textbox">
            <r-textbox
              class="admin-createcustomer--main--content--item text-box"
              type="string"
              v-model="smaregiClientID"
              name="smaregiClientID"
              :placeholder="'スマレジクライアントIDを入力'"
              :disabled="!canSmaregiInput"
              :error="errorFlag.smaregiClientIDInvalid"
            />
          </div>
        </div>

        <div class="admin-createcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.smaregiSecret")
            }}<span v-if="isSmaregiAnyInput" class="required-mark"
              >&nbsp;*</span
            >
          </r-heading>
          <div class="wrapper-for-textbox">
            <r-textbox
              class="admin-createcustomer--main--content--item text-box"
              type="string"
              v-model="smaregiSecret"
              name="smaregiSecret"
              :placeholder="'スマレジシークレットキーを入力'"
              :disabled="!canSmaregiInput"
              :error="errorFlag.smaregiSecretInvalid"
            />
          </div>
        </div>

        <div class="admin-createcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.fooding") }}
          </r-heading>
          <div class="wrapper-for-textbox">
            <r-textbox
              class="admin-createcustomer--main--content--item text-box"
              type="string"
              v-model="fooding"
              name="fooding"
              :placeholder="
                isFoodingInput ? '' : 'JSON形式でユーザID,パスワードを登録'
              "
            />
            <!-- :error="errorFlag.corpNoInvalid" -->
          </div>
        </div>
        <!-- API連携 -->
        <div class="admin-createcustomer--main--content">
          <r-heading :level="4"> API連携 </r-heading>
          <div class="admin-createcustomer--main--content--inner">
            <div class="wrapper-for-textbox headquarter-user-id">
              <r-textbox
                class="admin-createcustomer--main--content--item"
                type="string"
                name="apiKey"
                v-model="apiKey"
                :placeholder="apiKey ? '' : 'APIキーを入力'"
              ></r-textbox>
            </div>
            <!--生成ボタン-->
            <r-button
              v-if="!apiKey"
              class="admin-createcustomer--main--content create-random-charater"
              buttonStyle="secondary"
              @click="generateApiKey"
              >APIキー発行</r-button
            >
            <r-button
              v-else
              class="admin-createcustomer--main--content create-random-charater"
              buttonStyle="secondary"
              @click="generateApiKey"
              >APIキー再発行</r-button
            >
          </div>
        </div>
        <div class="admin-createcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.sharedMode") }}
          </r-heading>
          <div class="admin-createcustomer-checkbox">
            <input type="checkbox" id v-model="sharedMode" />
            {{ $t("admin.customerList.sharedMode") }}
          </div>
        </div>
        <div class="admin-createcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.level5") }}
          </r-heading>
          <div class="admin-createcustomer-checkbox">
            <input type="checkbox" id v-model="level5" />
            {{ $t("admin.customerList.chdckLevel5") }}
          </div>
        </div>
        <div class="admin-createcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.allergyTwenty") }}
          </r-heading>

          <div class="admin-createcustomer-checkbox">
            <input type="checkbox" id v-model="allergyTwenty" />
            {{ $t("admin.customerList.chdckAllergyTwenty") }}
          </div>
        </div>
        <div class="admin-createcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.smaregiHeadquarter") }}
          </r-heading>

          <div class="admin-createcustomer-checkbox">
            <div class="admin-editcustomer-checkbox">
              <input
                type="radio"
                id="one"
                value="false"
                v-model="smaregiHeadquarter"
                name="smaregiHeadquarter"
              />
              <label for="one">{{
                $t("admin.customerList.chdckSmaregiModeStore")
              }}</label>
              <br />
              <input
                type="radio"
                id="two"
                value="true"
                v-model="smaregiHeadquarter"
                name="smaregiHeadquarter"
              />
              <label for="two">{{
                $t("admin.customerList.chdckSmaregiModeHeadquarter")
              }}</label>
            </div>
          </div>
        </div>
        <!-- 本部ユーザ -->
        <div class="admin-createcustomer--main--content">
          <r-heading :level="4">
            本部ユーザ名<span class="required-mark">&nbsp;*</span>
          </r-heading>
          <!-- 本部ユーザ名 -->
          <div class="admin-createcustomer--main--content--inner">
            <div class="wrapper-for-textbox headquarter-user-id">
              <r-textbox
                class="admin-createcustomer--main--content--item"
                type="string"
                v-model="headquartersUser"
                name="headquartersUser"
                :placeholder="
                  isHeadquartersUserInput ? '' : '本部ユーザ名を入力'
                "
                :error="errorFlag.headquartersUserInvalid"
              />
            </div>
            <!--ランダム生成ボタン-->
            <r-button
              class="admin-createcustomer--main--content create-random-charater"
              buttonStyle="secondary"
              @click="generateUserID"
              >{{ $t("admin.customerList.generateUserID") }}</r-button
            >
          </div>
          <r-heading :level="4">
            {{ $t("admin.customerList.headquartersUser") }}パスワード<span
              class="required-mark"
              >&nbsp;*</span
            >
          </r-heading>
          <!-- 本部パスワード -->
          <div class="admin-createcustomer--main--content--inner">
            <div class="wrapper-for-textbox headquarter-user-id">
              <r-textbox
                class="admin-createcustomer--main--content--item"
                type="string"
                v-model="headquartersPassword"
                name="headquartersPassword"
                :placeholder="
                  isHeadquartersPasswordInput ? '' : '本部パスワードを入力'
                "
                :error="errorFlag.headquartersPasswordInvalid"
              />
            </div>
            <!--生成ボタン-->
            <r-button
              class="admin-createcustomer--main--content create-random-charater"
              buttonStyle="secondary"
              @click="generatePassword"
              >{{ $t("admin.customerList.generatePassword") }}</r-button
            >
          </div>
        </div>

        <div class="admin-createcustomer--main--content">
          <r-heading :level="4">
            {{ $t("admin.customerList.displayName")
            }}<span class="required-mark">&nbsp;*</span>
          </r-heading>
          <div class="wrapper-for-textbox">
            <r-textbox
              class="admin-createcustomer--main--content--item text-box"
              type="string"
              v-model="displayName"
              name="displayName"
              :placeholder="isDisplayNameInput ? '' : '本部ユーザ表示名を入力'"
              :error="errorFlag.displayNameInvalid"
            />
          </div>
        </div>

        <div class="admin-createcustomer--required-item-instruction">
          「<span class="required-mark">&nbsp;*&nbsp;</span>」は必須項目です。
        </div>
        <!--入力画面を確認する-->
        <div class="admin-createcustomer-btn-wrapper">
          <r-button
            class="admin-createcustomer--main--content confirm-btn"
            buttonStyle="primary"
            @click="inputConfirm"
            >{{ $t("admin.userEditForm.inputConfirm") }}</r-button
          >
        </div>
      </div>
      <!--入力確認画面-->
      <div v-else class="admin-createcustomer-content-wrapper">
        <dl class="admin-createcustomer-dl">
          <dt>
            {{ $t("admin.customerList.customerName")
            }}<span class="required-mark">&nbsp;*</span>
          </dt>
          <dd>{{ customerName }}</dd>
          <dt>
            {{ $t("admin.customerList.expireDate")
            }}<span class="required-mark">&nbsp;*</span>
          </dt>
          <dd>{{ formateDate }}</dd>
          <dt>{{ $t("admin.customerList.corpNo") }}</dt>
          <dd v-if="isCorpNoInput">{{ corpNo }}</dd>
          <dd v-else>未入力</dd>

          <dt>{{ $t("admin.customerList.smaregiContractID") }}</dt>
          <dd v-if="isSmaregiContractIDInput">{{ smaregiContractID }}</dd>
          <dd v-else>未入力</dd>
          <dt>{{ $t("admin.customerList.smaregiClientID") }}</dt>
          <dd v-if="isSmaregiClientIDInput">{{ smaregiClientID }}</dd>
          <dd v-else>未入力</dd>
          <dt>{{ $t("admin.customerList.smaregiSecret") }}</dt>
          <dd v-if="isSmaregiSecretInput">{{ smaregiSecret }}</dd>
          <dd v-else>未入力</dd>
          <dt>{{ $t("admin.customerList.smaregiHeadquarter") }}</dt>
          <dd v-if="true">
            {{ $t("admin.customerList.chdckSmaregiModeHeadquarter") }}
          </dd>
          <dd v-else>{{ $t("admin.customerList.chdckSmaregiModeStore") }}</dd>

          <dt>{{ $t("admin.customerList.fooding") }}</dt>
          <dd v-if="isFoodingInput">{{ fooding }}</dd>
          <dd v-else>未入力</dd>
          <dt>API連携</dt>
          <dd v-if="apiKey">{{ apiKey }}</dd>
          <dd v-else>未入力</dd>
          <dt>{{ $t("admin.customerList.sharedMode") }}</dt>
          <dd v-if="sharedMode">{{ $t("common.use") }}</dd>
          <dd v-else>{{ $t("common.unUse") }}</dd>
          <dt>{{ $t("admin.customerList.level5") }}</dt>
          <dd v-if="level5">{{ $t("common.use") }}</dd>
          <dd v-else>{{ $t("common.unUse") }}</dd>
          <dt>{{ $t("admin.customerList.allergyTwenty") }}</dt>
          <dd v-if="allergyTwenty">{{ $t("common.use") }}</dd>
          <dd v-else>{{ $t("common.unUse") }}</dd>
          <dt>
            {{ $t("admin.customerList.headquartersUser") }}
            <span class="required-mark">&nbsp;*</span>
          </dt>
          <dd>{{ headquartersUser }}</dd>
          <dt>
            {{ $t("admin.customerList.headquartersPassword") }}
            <span class="required-mark">&nbsp;*</span>
          </dt>
          <dd>{{ headquartersPassword }}</dd>
          <dt>本部ユーザ表示名 <span class="required-mark">&nbsp;*</span></dt>
          <dd>{{ displayName }}</dd>
        </dl>
        <div class="admin-createcustomer-btn-wrapper">
          <r-button
            buttonStyle="primary"
            full
            @click="inputCorrection"
            class="admin-createcustomer-btn confirm-btn"
            >{{ $t("admin.userEditForm.inputCorrection") }}</r-button
          >
          <r-button
            buttonStyle="primary"
            full
            @click="regist"
            class="admin-createcustomer-btn confirm-btn"
            >{{ $t("admin.userEditForm.regist") }}</r-button
          >
        </div>
      </div>
    </r-modal-sub-header>
  </r-paper>
</template>
<script>
import RTextbox from "@/plugins/atoms/RTextbox.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RModalSubHeader from "@/plugins/molecules/RModalSubHeader.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";
// import RRadio from "@/plugins/atoms/RRadio.vue";

import auth from "@/libs/Auth.js";
import customerManager from "@/libs/Customer.js";
import userManager from "@/libs/User.js";

import Datepicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";

export default {
  components: {
    RTextbox,
    RButton,
    RHeading,
    RPaper,
    RModalSubHeader,
    Datepicker,
    RSpinner,
    // RRadio,
  },
  data() {
    return {
      smaregiHeadquarter: false,
      display: false,
      displayConfirm: false,
      confirm: false,
      customerName: "",
      expireDate: "",
      corpNo: "",
      apiKey: "",
      fooding: "",
      sharedMode: false,
      level5: false,
      allergyTwenty: false,
      headquartersUser: "",
      headquartersPassword: "",
      displayName: "",
      smaregiContractID: "",
      smaregiClientID: "",
      smaregiSecret: "",
      ja: ja,
      DatePickerFormat: "yyyy/MM/dd",
      opts: [
        { id: 1, label: "A", value: true },
        { id: 2, label: "B", value: false },
      ],
      errorFlag: {
        nameInvalid: false,
        dateInvalid: false,
        corpNoInvalid: false,
        smaregiContractIDInvalid: false,
        smaregiClientIDInvalid: false,
        smaregiSecretInvalid: false,
        headquartersUserInvalid: false,
        headquartersPasswordInvalid: false,
        displayNameInvalid: false,
      },
      isShowSpinner: false,
    };
  },
  computed: {
    formateDate() {
      return `${this.expireDate.getFullYear()}/${
        this.expireDate.getMonth() + 1
      }/${this.expireDate.getDate()}`;
    },
    isCustomerNameInput() {
      if (this.customerName !== "") {
        return true;
      } else {
        return false;
      }
    },
    isExpireDateSelected() {
      if (this.expireDate !== "") {
        return true;
      } else {
        return false;
      }
    },
    isCorpNoInput() {
      if (this.corpNo !== "") {
        return true;
      } else {
        return false;
      }
    },
    isFoodingInput() {
      if (this.fooding !== "") {
        return true;
      } else {
        return false;
      }
    },
    isHeadquartersUserInput() {
      if (this.headquartersUser !== "") {
        return true;
      } else {
        return false;
      }
    },
    isHeadquartersPasswordInput() {
      if (this.headquartersPassword !== "") {
        return true;
      } else {
        return false;
      }
    },
    isDisplayNameInput() {
      if (this.displayName !== "") {
        return true;
      } else {
        return false;
      }
    },
    isSmaregiContractIDInput() {
      if (this.smaregiContractID) {
        return true;
      } else {
        return false;
      }
    },
    isSmaregiClientIDInput() {
      if (this.smaregiClientID) {
        return true;
      } else {
        return false;
      }
    },
    isSmaregiSecretInput() {
      if (this.smaregiSecret) {
        return true;
      } else {
        return false;
      }
    },
    isSmaregiAnyInput() {
      if (
        this.isSmaregiContractIDInput ||
        this.isSmaregiClientIDInput ||
        this.isSmaregiSecretInput
      ) {
        return true;
      } else {
        return false;
      }
    },
    canCorpNoInput() {
      if (
        !this.isSmaregiContractIDInput &&
        !this.isSmaregiClientIDInput &&
        !this.isSmaregiSecretInput
      ) {
        return true;
      } else {
        return false;
      }
    },
    canSmaregiInput() {
      if (!this.isCorpNoInput) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    inputConfirm() {
      // ToDo: 入力チェックを行う
      if (
        this.isCustomerNameInput &&
        this.isExpireDateSelected &&
        this.isHeadquartersUserInput &&
        this.isHeadquartersPasswordInput &&
        this.isDisplayNameInput &&
        (this.isSmaregiAnyInput
          ? this.isSmaregiContractIDInput &&
            this.isSmaregiClientIDInput &&
            this.isSmaregiSecretInput
          : true)
      ) {
        //確認画面に転換
        this.confirm = true;
        //エラーflag初期化
        this.errorFlag.nameInvalid = false;
        this.errorFlag.dateInvalid = false;
        this.errorFlag.headquartersUserInvalid = false;
        this.errorFlag.headquartersPasswordInvalid = false;
        this.errorFlag.displayNameInvalid = false;
        this.errorFlag.smaregiContractIDInvalid = false;
        this.errorFlag.smaregiClientIDInvalid = false;
        this.errorFlag.smaregiSecretInvalid = false;
      } else {
        //顧客名称
        if (!this.isCustomerNameInput) {
          this.errorFlag.nameInvalid = true;
        } else {
          this.errorFlag.nameInvalid = false;
        }
        //使用期限
        if (!this.isExpireDateSelected) {
          this.errorFlag.dateInvalid = true;
        } else {
          this.errorFlag.dateInvalid = false;
        }
        //本部ユーザ
        if (!this.isHeadquartersUserInput) {
          this.errorFlag.headquartersUserInvalid = true;
        } else {
          this.errorFlag.headquartersUserInvalid = false;
        }
        //本部パスワード
        if (!this.isHeadquartersPasswordInput) {
          this.errorFlag.headquartersPasswordInvalid = true;
        } else {
          this.errorFlag.headquartersPasswordInvalid = false;
        }
        //企業番号
        if (!this.isDisplayNameInput) {
          this.errorFlag.displayNameInvalid = true;
        } else {
          this.errorFlag.corpNoInvalid = false;
        }

        // スマレジ情報
        if (this.isSmaregiAnyInput) {
          if (!this.isSmaregiContractIDInput) {
            this.errorFlag.smaregiContractIDInvalid = true;
          }

          if (!this.isSmaregiClientIDInput) {
            this.errorFlag.smaregiClientIDInvalid = true;
          }

          if (!this.isSmaregiSecretInput) {
            this.errorFlag.smaregiSecretInvalid = true;
          }
        }

        alert("入力漏れ、もしくは選択漏れがあります。");
      }
    },
    inputCorrection() {
      this.confirm = false;
    },
    async regist() {
      this.isShowSpinner = true;
      try {
        const customerInfo = {
          customerName: this.customerName,
          expireDate: this.formateDate,
          corpNo: this.corpNo,
          fooding: this.fooding,
          apiKey: this.apiKey,
          copy: this.sharedMode,
          level5: this.level5,
          allergyTwenty: this.allergyTwenty,
          headquartersUser: this.headquartersUser,
          headquartersPassword: this.headquartersPassword,
          displayName: this.displayName,
          smaregiContractID: this.smaregiContractID,
          smaregiClientID: this.smaregiClientID,
          smaregiSecret: this.smaregiSecret,
          smaregiHeadquarter: Boolean(this.smaregiHeadquarter),
        };
        // ユーザ情報を取得
        await customerManager.createCustomer(customerInfo);
        alert("顧客登録が完了しました。");
        this.isShowSpinner = false;
        this.$emit("createCustomerComplete");
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("ERROR", error);
      }
    },
    generateRandomString(limit) {
      return userManager.generateRandomString(limit);
    },
    generateUserID() {
      this.headquartersUser = this.generateRandomString(8);
    },
    generatePassword() {
      this.headquartersPassword = this.generateRandomString(10);
    },
    generateApiKey() {
      this.apiKey = userManager.generateApiKey();
    },
  },
  async created() {
    try {
      const isAdmin = await auth.isAdmin();
      if (!isAdmin) {
        throw new Error("Forbidden");
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
  },
  beforeDestroy() {
    this.isShowSpinner = false;
  },
};
</script>

<style scoped>
.admin-createcustomer--main {
  padding: 20px;
}
.admin-createcustomer--main--content {
  margin-top: 20px;
}
.admin-createcustomer--required-item-instruction {
  margin-top: 20px;
  text-align: left;
}
.admin-createcustomer--main--content--item {
  margin-top: 10px;
  margin-right: 20px;
}
.text-box {
  width: 60%;
}
.admin-createcustomer--main--content--inner {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  width: 100%;
}
.wrapper-for-textbox {
  padding-left: 20px;
  display: flex;
}
.headquarter-user-id {
  width: 60%;
}
.create-random-charater {
  height: 43px;
  margin-top: unset;
  width: 20%;
}
.admin-createcustomer-dl {
  text-align: left;
}
.admin-createcustomer-btn {
  margin: 10px 0px 10px 0px;
}
.confirm-btn {
  padding: 5px;
  width: 100%;
}
.admin-createcustomer-btn-wrapper {
  margin: 40px 0px 0px;
}
dl dt {
  font-weight: bold;
  margin-bottom: 10px;
  margin-left: 10px;
}
dl dd {
  margin-bottom: 20px;
}
.admin-createcustomer-checkbox {
  display: block;
  text-align: left;
  margin: 10px 0px 20px 20px;
}
.required-mark {
  font-size: 1.2rem;
  color: #e85951;
}
</style>
<style module>
.datepickerwrapper {
  display: flex;
  justify-content: start;
  align-items: center;
}
.datepickerinput {
  border-radius: 4px;
  border: 2px solid #e6e6e6;
  padding: 8px;
  font-size: 1.2rem;
  width: 100%;
  margin-left: 20px;
}
.invalid {
  border-radius: 4px;
  border: 2px solid #e85951;
  padding: 8px;
  font-size: 1.2rem;
  width: 100%;
}
</style>
