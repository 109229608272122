import { functions } from "@/libs";
import { httpsCallable } from "firebase/functions";

export default {
  async createCustomer(customerInfo) {
    try {
      if (!customerInfo) {
        throw new Error("No customerInfo");
      }

      const createAdminCustomer = httpsCallable(
        functions,
        "createAdminCustomer",
      );
      await createAdminCustomer({
        name: customerInfo.customerName,
        expireDate: customerInfo.expireDate || "",
        corpNo: customerInfo.corpNo || "",
        apiKey: customerInfo.apiKey || "",
        fooding: customerInfo.fooding ? JSON.parse(customerInfo.fooding) : [],
        copy: customerInfo.copy || false,
        level5: customerInfo.level5 || false,
        allergyTwenty: customerInfo.allergyTwenty || false,
        headquartersUser: customerInfo.headquartersUser || "",
        headquartersPassword: customerInfo.headquartersPassword || "",
        displayName: customerInfo.displayName || "",
        smaregiContractID: customerInfo.smaregiContractID || "",
        smaregiClientID: customerInfo.smaregiClientID || "",
        smaregiSecret: customerInfo.smaregiSecret || "",
        smaregiHeadquarter: customerInfo.smaregiHeadquarter || false,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("ERROR", error);
      throw new Error(error);
    }
  },
  async getCustomerList() {
    let customerList = [];

    try {
      const getAdminCustomerList = httpsCallable(
        functions,
        "getAdminCustomerList",
      );
      const getdata = await getAdminCustomerList();
      if (getdata.data) {
        customerList = getdata.data;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("==error when getAdminCustomerList==", error);

      console.error(error);
      throw new Error(error);
    }
    return customerList;
  },
  async getCustomerInfo(id) {
    let customerInfo = {};
    try {
      if (!id) {
        throw new Error("No ID");
      }
      const getAdminCustomerInfo = httpsCallable(
        functions,
        "getAdminCustomerInfo",
      );
      const resp = await getAdminCustomerInfo({ id });
      if (resp.data) {
        customerInfo = resp.data;
        customerInfo.id = id;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }

    return customerInfo;
  },
  async updateCustomer(customerInfo) {
    try {
      if (!customerInfo) {
        throw new Error("No customerInfo for updating");
      }

      const updateAdminCustomer = httpsCallable(
        functions,
        "updateAdminCustomer",
      );
      await updateAdminCustomer({
        id: customerInfo.id,
        name: customerInfo.customerName,
        expireDate: customerInfo.expireDate || "",
        corpNo: customerInfo.corpNo || "",
        apiKey: customerInfo.apiKey || "",
        fooding: customerInfo.fooding ? JSON.parse(customerInfo.fooding) : [],
        copy: customerInfo.copy || false,
        level5: customerInfo.level5 || false,
        allergyTwenty: customerInfo.allergyTwenty || false,
        uid: customerInfo.uid,
        userID: customerInfo.userID,
        password: customerInfo.password || "",
        displayName: customerInfo.displayName || "",
        smaregiContractID: customerInfo.smaregiContractID || "",
        smaregiClientID: customerInfo.smaregiClientID || "",
        smaregiSecret: customerInfo.smaregiSecret || "",
        smaregiHeadquarter: customerInfo.smaregiHeadquarter || false,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("ERROR", error);
      throw new Error(error);
    }
  },
  async deleteCustomer(id) {
    try {
      if (!id) {
        throw new Error("CustomerID is required");
      }
      const deleteAdminCustomer = httpsCallable(
        functions,
        "deleteAdminCustomer",
      );
      await deleteAdminCustomer({ id });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("ERROR", error);
      throw new Error(error);
    }
  },
  async downloadCSV(id) {
    let csvData = "";
    try {
      if (!id) {
        throw new Error("No ID");
      }
      const getAdminRecipeList = httpsCallable(functions, "getAdminRecipeList");
      const resp = await getAdminRecipeList({ id });
      if (resp.data) {
        csvData = resp.data;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }

    return csvData;
  },
};
