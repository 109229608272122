<template>
  <div class="r-tab-for-pc">
    <div
      class="r-tab-for-pc--label"
      :class="{ 'r-tab-for-pc__active': isShow }"
      @click="clickHandler"
    >
      {{ label }}
    </div>
    <div class="r-tab-for-pc--content" v-if="isShow">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    clickHandler() {
      this.isShow = !this.isShow;
    },
  },
  props: {
    label: String,
  },
};
</script>

<style scoped>
.r-tab-for-pc {
  text-align: left;
  width: 100%;
}

.r-tab-for-pc--label {
  background-color: #ffffff;
  box-sizing: border-box;
  border-left: 5px solid #e87352;
  padding: 10px;
  font-weight: bold;
  border-radius: 0 5px 5px 0;
}
.r-tab-for-pc--label:hover {
  cursor: pointer;
}
.r-tab-for-pc__active {
  background-color: #e87352;
  color: #ffffff;
  border-radius: 0 5px 0 0;
}
</style>
