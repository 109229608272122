<template>
  <r-modal
    class="csv-form--content"
    :title="$t('admin.adminFileForm.formTitle')"
    @cancel="cancel"
  >
    <div class="error-message">{{ error }}</div>
    <r-csvupload
      @change="changeCsv"
      :csv="csv"
      class="admin-csv-form-csv-upload"
    >
      {{ $t("admin.adminFileForm.selectFile") }}
    </r-csvupload>
  </r-modal>
</template>

<script>
import RCsvupload from "@/plugins/atoms/RCsvupload.vue";
import RModal from "@/plugins/molecules/RModal.vue";

export default {
  name: "admin-csv-form",
  components: {
    RCsvupload,
    RModal,
  },
  props: {
    selectCustomerID: String,
    editStoreID: String,
  },
  data() {
    return {
      csv: "",
      error: "",
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    changeCsv(value) {
      this.csv = value;
      this.$emit("enter", this.csv);
    },
  },
};
</script>

<style scoped>
.csv-form--content {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #e87352;
  padding: 80px 20px;
  height: 450px !important;
}
.error-message {
  height: 10px;
  margin: 0 0 10px 0;
  color: #e85951;
  font-weight: bold;
}
.admin-csv-form-csv-upload >>> .csv-file-error-msg {
  display: block;
  margin-bottom: 40px;
}
</style>
