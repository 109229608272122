<template>
  <button
    type="button"
    class="r-button"
    :class="[
      buttonStyle ? 'r-button--' + buttonStyle : '',
      outline ? 'r-button--' + buttonStyle + '--outline' : '',
      {
        'r-button--round': round,
        'r-button--full': full,
        'r-button--icon': icon,
      },
    ]"
    :style="{
      'background-color': customColor,
      'border-color': customColor,
    }"
    @click="clickHandler"
    :disabled="disabled"
  >
    <i :class="['fa', 'fa-' + icon]" v-if="icon"></i>
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "r-button",
  props: {
    buttonStyle: {
      type: String,
      default: "",
    },
    // fontawesome用
    icon: {
      type: String,
      default: "",
    },
    disabled: Boolean,
    round: Boolean,
    outline: Boolean,
    full: Boolean,
    customColor: String,
  },
  methods: {
    clickHandler(e) {
      this.$emit("click", e);
    },
  },
};
</script>

<style scoped>
.r-button {
  --primary-color: #e87352;
  --secondary-color: #517ce8;
  --secondary-disabled: #85a1eb;
  border-radius: 5px;
  border: 2px solid;
  font-size: 1rem;
  font-weight: bold;
  background-color: #ffffff;
  appearance: none;
  outline: none;
}
.r-button:hover {
  cursor: pointer;
}
/* buttonStyle */
.r-button--primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #ffffff;
}
.r-button--primary:hover,
.r-button--secondary:hover {
  opacity: 0.7;
}
.r-button--secondary {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  color: #ffffff;
}
/* round */
.r-button--round {
  border-radius: 45px;
}
/* full */
.r-button--full {
  width: 100%;
}
/* outline */
.r-button--primary--outline {
  background: #ffffff;
  color: var(--primary-color);
}
.r-button--primaty--outline:hover {
  background: var(--primary-color);
  color: #ffffff;
}
.r-button--primary--outline:hover {
  background: var(--primary-color);
  color: #ffffff;
  cursor: pointer;
  transition: 0.5s;
}
.r-button--secondary--outline {
  background: #ffffff;
  color: var(--secondary-color);
}
</style>
