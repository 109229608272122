<template>
  <div>
    <div class="r-list-content" @click="clickHandler">
      <div class="r-list-content--icon" v-if="sub">
        <div v-if="!isShowSub">▼</div>
        <div v-else>▲</div>
      </div>
      <div
        class="r-list-content--wrapper"
        :class="{
          'r-list-content--clickable': clickable,
          'r-list-content--clickable': sub,
        }"
      >
        <slot></slot>
      </div>
    </div>
    <r-hr hrStyle="gray" />
    <div class="r-list--sub-content" v-if="isShowSub">
      <slot name="sub"></slot>
    </div>
  </div>
</template>

<script>
import RHr from "@/plugins/atoms/RHorizontalRule.vue";

export default {
  name: "r-list-content",
  components: {
    RHr,
  },
  methods: {
    clickHandler(e) {
      if (this.clickable) {
        this.$emit("click", e);
      }

      if (this.sub) {
        this.isShowSub = !this.isShowSub;
      }
    },
  },
  props: {
    clickable: Boolean,
    sub: Boolean,
  },
  data() {
    return {
      isShowSub: false,
    };
  },
};
</script>

<style scoped>
.r-list-content {
  display: flex;
  align-items: center;
  width: 100%;
}
.r-list-content:hover {
  cursor: pointer;
  opacity: 0.7;
}
.r-list-content--icon {
  color: #517ce8;
}
.r-list-content--wrapper {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  padding: 0 10px;
}
.r-list-content--clickable:hover {
  cursor: pointer;
}
</style>
