<template>
  <div class="admin-list">
    <div class="r-card--wrapper">
      <div class="admin-main">
        <!-- タブここから -->
        <r-tabs v-if="!isWindowPC">
          <r-tab :label="$t('admin.adminMenuTop.customerManager')">
            <r-list>
              <r-list-content
                v-for="(tabContent, index) in tabContents.customer"
                :key="index"
                @click="toggleAdmin(index)"
                clickable
                :class="{ active: selectValue === index }"
              >
                <r-list-item>{{ tabContent }}</r-list-item>
              </r-list-content>
            </r-list>
          </r-tab>
          <r-tab :label="$t('admin.adminMenuTop.storeManager')">
            <r-list>
              <r-list-content
                v-for="(tabContent, index) in tabContents.store"
                :key="index"
                @click="toggleAdmin(index + tabContents.customer.length)"
                clickable
                :class="{
                  active: selectValue === index + tabContents.customer.length,
                }"
              >
                <r-list-item>{{ tabContent }}</r-list-item>
              </r-list-content>
            </r-list>
          </r-tab>
          <r-tab :label="$t('admin.adminMenuTop.userManager')">
            <r-list>
              <r-list-content
                v-for="(tabContent, index) in tabContents.user"
                :key="index"
                @click="
                  toggleAdmin(
                    index +
                      tabContents.customer.length +
                      tabContents.store.length,
                  )
                "
                clickable
                :class="{
                  active:
                    selectValue ===
                    index +
                      tabContents.customer.length +
                      tabContents.store.length,
                }"
              >
                <r-list-item>{{ tabContent }}</r-list-item>
              </r-list-content>
            </r-list>
          </r-tab>
        </r-tabs>

        <div class="admin-main--left" v-if="isWindowPC">
          <r-tab-for-pc
            class="admin-main--left--items"
            :label="$t('admin.adminMenuTop.customerManager')"
          >
            <r-list>
              <r-list-content
                class="admin-main--left--items--item"
                :class="{ active: selectValue === 0 }"
                @click="toggleAdmin(0)"
                clickable
              >
                <r-list-item>
                  {{ $t("admin.adminMenuTop.createCustomer") }}
                </r-list-item>
              </r-list-content>

              <r-list-content
                class="admin-main--left--items--item"
                :class="{ active: selectValue === 1 }"
                @click="toggleAdmin(1)"
                clickable
              >
                <r-list-item>
                  {{ $t("admin.adminMenuTop.editCustomer") }}
                </r-list-item>
              </r-list-content>
            </r-list>
          </r-tab-for-pc>

          <r-tab-for-pc
            class="admin-main--left--items"
            :label="$t('admin.adminMenuTop.storeManager')"
          >
            <r-list>
              <r-list-content
                class="admin-main--left--items--item"
                :class="{ active: selectValue === 2 }"
                @click="toggleAdmin(2)"
                clickable
              >
                <r-list-item>
                  {{ $t("admin.adminMenuTop.createStore") }}
                </r-list-item>
              </r-list-content>

              <r-list-content
                class="admin-main--left--items--item"
                :class="{ active: selectValue === 3 }"
                @click="toggleAdmin(3)"
                clickable
              >
                <r-list-item>
                  {{ $t("admin.adminMenuTop.editStore") }}
                </r-list-item>
              </r-list-content>

              <r-list-content
                class="admin-main--left--items--item"
                :class="{ active: selectValue === 4 }"
                @click="toggleAdmin(4)"
                clickable
              >
                <r-list-item>
                  {{ $t("admin.adminMenuTop.registMaster") }}
                </r-list-item>
              </r-list-content>
            </r-list>
          </r-tab-for-pc>

          <r-tab-for-pc
            class="admin-main--left--items"
            :label="$t('admin.adminMenuTop.userManager')"
          >
            <r-list>
              <r-list-content
                class="admin-main--left--items--item"
                :class="{ active: selectValue === 5 }"
                @click="toggleAdmin(5)"
                clickable
              >
                <r-list-item>
                  {{ $t("admin.adminMenuTop.createUser") }}
                </r-list-item>
              </r-list-content>

              <r-list-content
                class="admin-main--left--items--item"
                :class="{ active: selectValue === 6 }"
                @click="toggleAdmin(6)"
                clickable
              >
                <r-list-item>
                  {{ $t("admin.adminMenuTop.editUser") }}
                </r-list-item>
              </r-list-content>
            </r-list>
          </r-tab-for-pc>
        </div>

        <!-- タブここまで -->

        <!-- メインコンテンツ -->
        <r-spinner v-if="isShowSpinner" />
        <div class="admin--main-contents" v-else>
          <r-paper paperStyle="white" v-if="selectValue < 0">
            <div class="admin-context" />
          </r-paper>
          <admin-create-customer-form
            v-if="selectValue === 0"
            @createCustomerComplete="updateComplete"
          />
          <admin-select-customer-form
            v-if="selectValue === 1"
            @edit="openEditCustomer"
          />
          <admin-create-store-form
            v-if="selectValue === 2"
            @createStoreComplete="updateComplete"
          />
          <admin-select-store-form
            v-if="selectValue === 3"
            @edit="openEditStore"
          />
          <admin-select-master-form
            v-if="selectValue === 4"
            @edit="openUploadFile"
          />
          <admin-createuser-form
            v-if="selectValue === 5"
            @createUserComplete="updateComplete"
          />
          <admin-selectuser-form
            v-if="selectValue === 6"
            @edit="openEditUser"
          />
          <admin-regist-master-form
            v-if="selectValue === 7"
            :selectCustomerID="selectCustomerID"
            :editStoreID="editStoreID"
            :csv="csv"
          />
        </div>

        <!-- モーダルコンテンツ -->
        <div class="modal-bg" v-if="editUserFlag" @click.self="closeEditUser">
          <admin-edituser-form
            class="modal"
            :editUserID="editUserID"
            v-if="editUserFlag"
            @updateAdminUserComplete="adminUserUpdateComplete"
          />
        </div>
        <div
          class="modal-bg"
          v-if="editCustomerFlag"
          @click.self="closeEditCustomer"
        >
          <admin-edit-customer-form
            v-if="editCustomerFlag"
            class="modal"
            :editCustomerID="editCustomerID"
            @updateCustomerComplete="customerUpdateComplete"
          />
        </div>
        <div class="modal-bg" v-if="editStoreFlag" @click.self="closeEditStore">
          <admin-edit-store-form
            v-if="editStoreFlag"
            class="modal"
            :selectCustomerID="selectCustomerID"
            :editStoreID="editStoreID"
            @close="closeEditStore"
          />
        </div>
        <div
          class="modal-bg"
          v-if="fileUploadFlag"
          @click.self="closeUploadFile"
        >
          <admin-csv-form
            v-if="fileUploadFlag"
            class="modal"
            @enter="uploadCSVFile"
            @cancel="closeUploadFile"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminCreateuserForm from "@/components/config/organisms/AdminCreateuserForm.vue";
import AdminSelectuserForm from "@/components/config/organisms/AdminSelectuserForm.vue";
import AdminEdituserForm from "@/components/config/organisms/AdminEdituserForm.vue";
import AdminCreateCustomerForm from "@/components/config/organisms/AdminCreateCustomerForm.vue";
import AdminSelectCustomerForm from "@/components/config/organisms/AdminSelectCustomerForm.vue";
import AdminEditCustomerForm from "@/components/config/organisms/AdminEditCustomerForm.vue";
import AdminCreateStoreForm from "@/components/config/organisms/AdminCreateStoreForm";
import AdminSelectStoreForm from "@/components/config/organisms/AdminSelectStoreForm.vue";
import AdminEditStoreForm from "@/components/config/organisms/AdminEditStoreForm.vue";
import AdminSelectMasterForm from "@/components/config/organisms/AdminSelectMasterForm.vue";
import AdminCsvForm from "@/components/config/organisms/AdminCsvForm.vue";
import AdminRegistMasterForm from "@/components/config/organisms/AdminRegistMasterForm.vue";

import RPaper from "@/plugins/atoms/RPaper.vue";
import RListContent from "@/plugins/atoms/RListContent.vue";
import RListItem from "@/plugins/atoms/RListItem.vue";
import RList from "@/plugins/atoms/RList.vue";
import RTabs from "@/plugins/atoms/RTabs.vue";
import RTab from "@/plugins/atoms/RTab.vue";
import RTabForPc from "@/plugins/atoms/RTabForPC.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import auth from "@/libs/Auth.js";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

export default {
  name: "config",
  components: {
    AdminCreateuserForm,
    AdminSelectuserForm,
    AdminEdituserForm,
    AdminCreateCustomerForm,
    AdminSelectCustomerForm,
    AdminEditCustomerForm,
    AdminCreateStoreForm,
    AdminSelectStoreForm,
    AdminEditStoreForm,
    AdminSelectMasterForm,
    AdminCsvForm,
    AdminRegistMasterForm,
    RListContent,
    RListItem,
    RPaper,
    RList,
    RTabs,
    RTab,
    RTabForPc,
    RSpinner,
  },
  data() {
    return {
      tabContents: {
        customer: [
          this.$i18n.t("admin.adminMenuTop.createCustomer"),
          this.$i18n.t("admin.adminMenuTop.editCustomer"),
        ],
        store: [
          this.$i18n.t("admin.adminMenuTop.createStore"),
          this.$i18n.t("admin.adminMenuTop.editStore"),
          this.$i18n.t("admin.adminMenuTop.registMaster"),
        ],
        user: [
          this.$i18n.t("admin.adminMenuTop.createUser"),
          this.$i18n.t("admin.adminMenuTop.editUser"),
        ],
      },
      csv: "",
      customers: {},
      stores: {},
      selectValue: -1,
      detacher: null,
      isWindowPC: false,
      editUserFlag: false,
      editUserID: "",
      editCustomerFlag: false,
      editCustomerID: "",
      editStoreFlag: false,
      fileUploadFlag: false,
      selectCustomerID: "",
      editStoreID: "",
      headingImage: require("../../../assets/images/settingColor.png"),
      isShowSpinner: false,
    };
  },
  methods: {
    updateComplete() {
      this.selectValue = -1;
    },
    toggleAdmin(index) {
      this.selectCustomerID = "";
      this.editStoreID = "";
      this.selectValue = index;
    },
    async setStore() {
      if (this.customerID) {
        this.stores = await auth.getStoreList(this.customerID);
      }
    },
    handleResize() {
      if (window.innerWidth <= 641) {
        this.isWindowPC = false;
      } else {
        this.isWindowPC = true;
      }
    },
    openEditUser(value) {
      this.editUserFlag = true;
      this.editUserID = value;
      //モーダルコンポーネント指定、モーダル背景固定処理
      const modal = document.querySelector(".modal");
      disableBodyScroll(modal);
    },
    closeEditUser() {
      this.editUserFlag = false;
      //モーダル背景固定解除
      clearAllBodyScrollLocks();
    },
    openEditCustomer(value) {
      this.editCustomerFlag = true;
      this.editCustomerID = value;
      //モーダルコンポーネント指定、モーダル背景固定処理
      const modal = document.querySelector(".modal");
      disableBodyScroll(modal);
    },
    adminUserUpdateComplete() {
      this.editUserFlag = false;
      //モーダル背景固定解除
      clearAllBodyScrollLocks();
      alert("adminユーザー情報の編集を完了しました。");
      //モーダル背景固定解除
      const modal = document.querySelector(".modal");
      enableBodyScroll(modal);
      clearAllBodyScrollLocks();
    },
    customerUpdateComplete() {
      this.editCustomerFlag = false;
      //モーダル背景固定解除
      clearAllBodyScrollLocks();
      alert(this.$i18n.t("admin.adminMenuTop.editUserComplete"));
      //モーダル背景固定解除
      const modal = document.querySelector(".modal");
      enableBodyScroll(modal);
      clearAllBodyScrollLocks();
    },
    closeEditCustomer() {
      this.editCustomerFlag = false;
      //モーダル背景固定解除
      clearAllBodyScrollLocks();
    },
    openEditStore(customerID, storeID) {
      this.editStoreFlag = true;
      this.selectCustomerID = customerID;
      this.editStoreID = storeID;
      //モーダルコンポーネント指定、モーダル背景固定処理
      const modal = document.querySelector(".modal");
      disableBodyScroll(modal);
    },
    closeEditStore() {
      this.editStoreFlag = false;
      const modal = document.querySelector(".modal");
      enableBodyScroll(modal);
      clearAllBodyScrollLocks();
    },
    openUploadFile(customerID, storeID) {
      this.fileUploadFlag = true;
      this.selectCustomerID = customerID;
      this.editStoreID = storeID;
      const modal = document.querySelector(".modal");
      disableBodyScroll(modal);
    },
    uploadCSVFile(strCsv) {
      // CSVファイル選択
      this.csv = strCsv;
      this.selectValue = 7;
      this.fileUploadFlag = false;
      const modal = document.querySelector(".modal");
      enableBodyScroll(modal);
      clearAllBodyScrollLocks();
    },
    closeUploadFile() {
      this.fileUploadFlag = false;
      const modal = document.querySelector(".modal");
      enableBodyScroll(modal);
      clearAllBodyScrollLocks();
    },
  },
  async created() {
    const isAdmin = await auth.isAdmin();
    if (!isAdmin) {
      throw new Error("Forbidden");
    }
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    if (this.detacher) {
      this.detacher();
    }
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.admin-list {
  padding: 20px 20px 0;
  max-height: 100% !important;
}
.admin-context {
  min-height: 30vh;
  background-image: url("~@/assets/images/logo4.png");
  background-size: auto 15vh;
  background-position: center center;
  background-repeat: no-repeat;
}
.admin--main-contents {
  margin-top: 20px;
}
.active {
  color: #e87352;
}
.modal-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.modal {
  width: 80%;
  height: 80%;
  overflow: auto;
}

@media screen and (min-width: 641px) {
  .admin-list {
    padding-top: 20px;
  }
  .admin-main {
    display: flex;
  }
  .admin-main--left {
    width: 30%;
    margin-right: 20px;
  }
  .admin--main-contents {
    width: 70%;
    margin-top: 0;
  }
  .admin-main--left--items:not(:first-child) {
    margin-top: 20px;
  }
  .admin-main--left--items--item {
    font-weight: bold;
  }
}

@media screen and (min-width: 1024px) {
  .admin-list {
    padding-top: 20px;
    width: 1024px;
    margin: auto;
  }
  .admin-main {
    display: flex;
  }
}
</style>
