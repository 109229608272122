import { functions } from "@/libs";
import { httpsCallable } from "firebase/functions";

export default {
  async createAdminUser(userInfo) {
    try {
      if (!userInfo) {
        throw new Error("No userInfo");
      }
      const createAdminUser = httpsCallable(functions, "createAdminUser");
      await createAdminUser({
        userID: userInfo.userID,
        password: userInfo.password,
        displayName: userInfo.displayName,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("ERROR", error);
      throw new Error(error);
    }
  },
  async getAdminUserList() {
    let userList = [];

    try {
      const getAdminUserList = httpsCallable(functions, "getAdminUserList");
      const getdata = await getAdminUserList();
      if (getdata.data) {
        userList = getdata.data;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    return userList;
  },
  async getAdminUserInfo(id) {
    let userInfo = {};
    try {
      if (!id) {
        throw new Error("No ID");
      }
      const getAdminUserInfo = httpsCallable(functions, "getAdminUserInfo");
      const resp = await getAdminUserInfo({ uid: id });
      if (resp.data) {
        userInfo = resp.data;
        userInfo.id = id;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }

    return userInfo;
  },
  async updateAdminUser(userInfo) {
    try {
      if (!userInfo) {
        throw new Error("No userInfo for updating");
      }
      const updateAdminUser = httpsCallable(functions, "updateAdminUser");
      await updateAdminUser({
        uid: userInfo.id,
        userID: userInfo.userID,
        password: userInfo.password,
        displayName: userInfo.displayName,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("ERROR", error);
      throw new Error(error);
    }
  },
  async deleteAdminUser(id) {
    try {
      if (!id) {
        throw new Error("No ID");
      }
      const deleteAdminUser = httpsCallable(functions, "deleteAdminUser");
      await deleteAdminUser({
        uid: id,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("ERROR", error);
      throw new Error(error);
    }
  },
  // ユーザーの最終ログイン日時で使用
  async getUserListWithLastLoginDate(customerID, storeID) {
    let userList = [];
    try {
      if (!customerID) {
        throw new Error("No customer ID");
      }
      const getUserList = httpsCallable(functions, "getUserList");
      const resp = await getUserList({
        customerID: customerID,
        storeID: storeID,
      });
      if (resp.data) {
        resp.data.map((doc) => {
          let strDate = "";
          const lastLoginDate = doc.lastLoginDate;
          if (lastLoginDate) {
            const dt = new Date(lastLoginDate);
            const y = dt.getFullYear();
            const mon = ("00" + (dt.getMonth() + 1)).slice(-2);
            const d = ("00" + dt.getDate()).slice(-2);
            const h = ("00" + dt.getHours()).slice(-2);
            const m = ("00" + dt.getMinutes()).slice(-2);
            const s = ("00" + dt.getSeconds()).slice(-2);
            strDate = `${y}/${mon}/${d} ${h}:${m}:${s}`;
          }
          const record = {
            name: doc.name,
            lastLoginDate: strDate || "",
          };
          userList.push(record);
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }

    return userList;
  },
  generateRandomString(limit) {
    let str = "";
    const strings =
      "ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789";
    const strLen = strings.length;
    for (let i = 0; i < limit; i++) {
      str += strings[Math.floor(Math.random() * strLen)];
    }
    return str;
  },
  generateApiKey() {
    const S =
      "ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789-._~/";
    const N = 64;

    const array = new Uint32Array(N);

    return Array.from(window.crypto.getRandomValues(array))
      .map((n) => {
        return S[n % S.length];
      })
      .join("");
  },
};
