<template>
  <div class="login-main">
    <r-paper class="login-main--paper" round>
      <div class="login-main--title">
        <span>{{ $t("auth.login") }}</span>
      </div>
      <span class="login-main--content login-main--caption">{{
        $t("auth.inputIdandPassword")
      }}</span>
      <div class="login-main--content">
        <r-textbox
          :label="$t('auth.userID')"
          type="string"
          v-model="username"
          name="username"
        />
        <r-textbox
          :label="$t('auth.password')"
          type="password"
          v-model="password"
          name="password"
        />
      </div>
      <r-button
        class="login-main--content"
        buttonStyle="primary"
        full
        @click="login"
        >{{ $t("auth.login") }}</r-button
      >
    </r-paper>
  </div>
</template>

<script>
import RTextbox from "@/plugins/atoms/RTextbox.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import auth from "@/libs/Auth.js";

export default {
  name: "login",
  components: {
    RTextbox,
    RPaper,
    RButton,
  },
  data() {
    return {
      username: "",
      password: "",
      isLoginPage: true, //login状態をemitするためのBoolean変数
    };
  },
  methods: {
    async login() {
      // ToDo: 入力値チェック
      try {
        const result = await auth.signInCheck(
          this.username.trim(),
          this.password.trim(),
        );
        if (result) {
          this.$router.push({ name: "admin" });
        } else {
          throw new Error("Forbidden");
        }
      } catch (e) {
        alert("login error: " + e.message);
      }
    },
  },
  async created() {
    // await this.$root.clearAllAction();
  },
};
</script>

<style scoped>
.login-main {
  width: 100%;
  min-height: 65vh;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.login-main--paper {
  width: 100%;
}
.login-main--title {
  text-align: left;
  padding: 10px;
  font-weight: bold;
  border-bottom: 2px solid #e87352;
}
.login-main--content {
  margin-top: 20px;
}
.login-main--caption {
  display: block;
  color: #686868;
  font-size: 0.8rem;
  text-align: left;
}
.header-blocker {
  height: 14vh;
}
/*iPad用css*/
@media (min-width: 641px) {
}
@media (min-width: 1024px) {
  .login-main {
    width: 60%;
    margin: auto;
  }
}
</style>
