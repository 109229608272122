<template>
  <r-spinner v-if="isShowSpinner" />
  <r-paper class="admin-category" round v-else>
    <r-modal-sub-header :title="$t('admin.customerList.customerList')" />
    <!--顧客選択エリア-->
    <span>編集・削除する顧客を選択(削除の場合は複数選択可)</span>
    <div class="admin-category--main--manipulate-customer">
      <div
        class="admin-category--main select-wrapper check-box-area"
        :class="errorFlag ? ' customer-not-selected' : ''"
      >
        <!--登録顧客がない場合-->
        <div v-if="customers.length <= 0">登録顧客：０名</div>
        <r-checkbox :items="customers" @toggle="toggle" />
      </div>
      <div class="edit-delete-btn-wrapper">
        <!--編集ボタン-->
        <r-button
          class="admin-category--button"
          buttonStyle="secondary"
          full
          @click="selectCustomer"
          >{{ $t("admin.customerList.editCustomer") }}</r-button
        >
        <!--削除ボタン-->
        <r-button
          class="admin-category--button"
          buttonStyle="secondary"
          full
          @click="deleteCustomer"
          >{{ $t("admin.customerList.deleteCustomer") }}</r-button
        >
      </div>
    </div>
    <!--ボタン-->
    <div class="btn-wrapper">
      <!--レシピダウンロードボタン-->
      <r-button
        class="admin-category--button"
        buttonStyle="secondary"
        full
        @click="downloadCSV"
        >{{ $t("admin.customerList.recipeDownload") }}</r-button
      >
    </div>
  </r-paper>
</template>
<script>
import RButton from "@/plugins/atoms/RButton.vue";
import RCheckbox from "@/plugins/atoms/RCheckbox.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RModalSubHeader from "@/plugins/molecules/RModalSubHeader.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import auth from "@/libs/Auth.js";
import customerManager from "@/libs/Customer.js";

export default {
  components: {
    RButton,
    RCheckbox,
    RPaper,
    RModalSubHeader,
    RSpinner,
  },
  data() {
    return {
      customers: [],
      inputName: "",
      errorFlag: false,
      isShowSpinner: false,
    };
  },
  computed: {
    isUserRegistered() {
      if (this.customers.length === 0) {
        return false;
      }
      return true;
    },
  },
  methods: {
    toggle(index) {
      this.$set(this.customers[index], "bool", !this.customers[index].bool);
    },
    selectCustomer() {
      if (this.customers.filter((e) => e.bool).length >= 2) {
        // 入力チェック:複数選択されていた場合、メッセージを表示する
        alert(this.$t("admin.customerList.selectOneCustomerInCaseOfEdit"));
        return;
      }
      if (this.customers.filter((e) => e.bool).length <= 0) {
        //入力チェック:顧客及び店舗の選択
        this.errorFlag = true;
        alert("編集する顧客が選択されていません。顧客の選択を行ってください。");
        return;
      }
      const index = this.customers.map((e) => e.bool).indexOf(true);
      //編集に切り替える
      this.$emit("edit", this.customers[index].id);
      //エラーflagの初期化
      this.errorFlag = false;
    },
    async deleteCustomer() {
      if (this.customers.filter((e) => e.bool).length <= 0) {
        //入力チェック:顧客及び店舗の選択
        this.errorFlag = true;
        alert("削除する顧客が選択されていません。顧客の選択を行ってください。");
        return;
      } else if (
        window.confirm("顧客のアカウント情報を削除します。よろしいですか？")
      ) {
        this.isShowSpinner = true;
        try {
          await Promise.all(
            this.customers.map(async (customer) => {
              if (customer.bool) {
                await customerManager.deleteCustomer(customer.id);
              }
            }),
          );
          //現在のページをreload
          this.$router.go({
            path: this.$router.currentRoute.path,
            force: true,
          });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          throw new Error(error);
        }
        alert("削除完了");
      }
    },
    async downloadCSV() {
      const Encoding = require("encoding-japanese");
      const FileSaver = require("file-saver");

      if (this.customers.filter((e) => e.bool).length >= 2) {
        // 入力チェック:複数選択されていた場合、メッセージを表示する
        alert(this.$t("admin.customerList.selectOneCustomerInCaseOfEdit"));
        return;
      }
      if (this.customers.filter((e) => e.bool).length <= 0) {
        //入力チェック:顧客及び店舗の選択
        this.errorFlag = true;
        alert("編集する顧客が選択されていません。顧客の選択を行ってください。");
        return;
      }
      const index = this.customers.map((e) => e.bool).indexOf(true);

      const csvData = await customerManager.downloadCSV(
        this.customers[index].id,
      );

      // 文字列をutfの配列に変換
      const unicodeList = [];
      for (let i = 0; i < csvData.length; i++) {
        unicodeList.push(csvData.charCodeAt(i));
      }
      // エンコード
      const sjisList = Encoding.convert(unicodeList, "SJIS", "UNICODE");

      // 変換
      const uint8Array = new Uint8Array(sjisList);
      let blob = new Blob([uint8Array], { type: "text/csv" });
      FileSaver.saveAs(blob, "recipes.csv");
    },
  },
  async created() {
    this.isShowSpinner = true;
    try {
      const isAdmin = await auth.isAdmin();
      if (!isAdmin) {
        throw new Error("Forbidden");
      }
      // 顧客一覧を取得
      const customerList = await customerManager.getCustomerList();
      this.customers = customerList.map((customer) => {
        const record = { id: customer.id, name: customer.name, bool: false };
        return record;
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    this.isShowSpinner = false;
  },
  beforeDestroy() {
    this.isShowSpinner = false;
  },
};
</script>

<style scoped>
.admin-category {
  padding: 20px;
}
.admin-category--main {
  text-align: left;
  border: 2px solid #e6e6e6;
  border-radius: 5px;
  padding: 10px;
  min-height: 25vh;
}
.select-wrapper {
  overflow: auto;
}
.customer-not-selected {
  border: 2px solid #e85951;
}
.admin-category--button {
  margin-top: 20px;
}
.btn-wrapper {
  margin-top: 20px;
}
@media screen and (min-width: 641px) {
  .admin-category--main--manipulate-customer {
    margin-top: 20px;
    display: grid;
    vertical-align: middle;
    grid-template-columns: 5fr 1fr;
    gap: 10px;
    min-height: 300px;
  }
  .check-box-area {
    grid-template-columns: 5fr;
  }
  .edit-delete-btn-wrapper {
    grid-template-columns: 1fr;
    margin: auto;
    display: block;
  }
  .admin-category--button {
    padding: 5px;
  }
  .admin-category--button:nth-of-type(2n) {
    margin-top: 20px;
  }
}
</style>
