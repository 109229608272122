import { functions } from "@/libs";
import { httpsCallable } from "firebase/functions";

export default {
  // マスターファイル取得
  async getMasterFile(customerID, storeID) {
    let masterData = [];
    if (!customerID || !storeID) {
      return masterData;
    }

    try {
      const getAdminMaster = httpsCallable(functions, "getAdminMaster");
      const result = await getAdminMaster({
        customerID: customerID,
        storeID: storeID,
      });
      if (result.data) {
        masterData = result.data;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
    return masterData;
  },
  // マスターファイル取得
  async setMasterFile(customerID, storeID, setData) {
    let masterData = [];
    if (!customerID || !storeID) {
      return masterData;
    }

    try {
      const setAdminMaster = httpsCallable(functions, "setAdminMaster");
      await setAdminMaster({
        customerID: customerID,
        storeID: storeID,
        setData: setData,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
    return masterData;
  },
};
