<template>
  <r-paper class="admin-category" round>
    <r-spinner v-if="isShowSpinner" />
    <div v-else>
      <div v-if="isDisplayMaster">
        <admin-upload-master-form
          :customerID="customerID"
          :storeID="storeID"
          :csv="csvData"
        />
      </div>
      <div v-else>
        <r-modal-sub-header :title="$t('admin.storeList.storeList')" />
        <!--店舗名を選択してください-->
        <div class="admin-category--main select-customer">
          <r-heading :level="4">{{ $t("auth.selectCustomerName") }}</r-heading>
          <div class="admin-select-store-checkbox">
            <r-select
              :label="selectLabel"
              :options="customers"
              @input="customerSelect"
              :error="errorFlag.customerNotSelected"
            />
          </div>
        </div>
        <!--店舗選択領域-->
        <r-spinner
          v-if="isShowSpinnerAtStoreList"
          class="admin-category--main"
        />
        <div v-else class="admin-select-master-wrapper">
          <div
            class="admin-category--main select-store-wrapper"
            :class="errorFlag.storeNotSelected ? ' store-not-selected' : ''"
          >
            <!--店舗登録0件-->
            <div v-if="stores.length <= 0 && customerID">
              店舗登録件数：０件
            </div>
            <r-checkbox :limit="1" :items="stores" @toggle="toggle" />
          </div>
          <!--新規登録ボタン-->
          <div class="admin-category--button-wrapper">
            <r-button
              class="admin-category--button"
              buttonStyle="secondary"
              full
              @click="uploadFile"
              >{{ $t("admin.adminFileForm.createMaster") }}</r-button
            >
            <!--編集ボタン-->
            <r-button
              class="admin-category--button"
              buttonStyle="secondary"
              full
              @click="editStoreMasterCsv"
              >{{ $t("admin.storeList.editStore") }}</r-button
            >
          </div>
        </div>
      </div>
    </div>
  </r-paper>
</template>
<script>
import RButton from "@/plugins/atoms/RButton.vue";
import RCheckbox from "@/plugins/atoms/RCheckbox.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RModalSubHeader from "@/plugins/molecules/RModalSubHeader.vue";
import RSelect from "@/plugins/atoms/RSelect.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";
import AdminUploadMasterForm from "@/components/config/organisms/AdminUploadMasterForm.vue";

import auth from "@/libs/Auth.js";
import customerManager from "@/libs/Customer.js";
import storeManager from "@/libs/Store.js";
import masterManager from "@/libs/Master.js";

export default {
  components: {
    RButton,
    RCheckbox,
    RPaper,
    RHeading,
    RModalSubHeader,
    RSelect,
    RSpinner,
    AdminUploadMasterForm,
  },
  data() {
    return {
      csvData: [], // マスターデータ
      selectLabel: "顧客名称を選択してください。",
      customerID: "",
      customers: {},
      storeID: "",
      stores: [],
      inputName: "",
      errorFlag: {
        customerNotSelected: false,
        storeNotSelected: false,
      },
      isDisplayMaster: false,
      isShowSpinner: false,
      isShowSpinnerAtStoreList: false,
    };
  },
  methods: {
    toggle(index) {
      this.$set(this.stores[index], "bool", !this.stores[index].bool);
    },
    async customerSelect(value) {
      if (value) {
        this.isShowSpinnerAtStoreList = true;
        this.customerID = value;
        const storeList = await storeManager.getStoreList(this.customerID);
        this.stores = storeList.map((store) => {
          const recoed = { id: store.id, name: store.name, bool: false };
          return recoed;
        });
        this.isShowSpinnerAtStoreList = false;
      }
    },
    // マスター編集
    async editStoreMasterCsv() {
      // 複数選択されていた場合、メッセージを表示する
      if (this.customerID === "") {
        //顧客名の選択漏れのチェック
        alert(
          "入力漏れ、もしくは選択漏れがあります。顧客の選択及び店舗の選択を行ってください。",
        );
        this.errorFlag.customerNotSelected = true;
        return;
      }
      //顧客errorFlag初期化
      this.errorFlag.customerNotSelected = false;
      if (this.stores.filter((e) => e.bool).length >= 2) {
        alert(this.$t("admin.customerList.selectOneCustomerInCaseOfEdit"));
        return;
      } else if (this.stores.filter((e) => e.bool).length <= 0) {
        this.errorFlag.storeNotSelected = true;
        alert(
          "入力漏れ、もしくは選択漏れがあります。顧客の選択及び店舗の選択を行ってください。",
        );
        return;
      }
      //編集画面
      const index = this.stores.map((e) => e.bool).indexOf(true);
      this.storeID = this.stores[index].id;

      this.isShowSpinner = true;
      // 登録しているマスターファイルを取得
      try {
        let result = false;
        this.csvData = await masterManager.getMasterFile(
          this.customerID,
          this.storeID,
        );
        if (this.csvData.length === undefined) {
          result = true;
        }
        if (result) {
          alert(
            "マスターファイルが未登録です。先にマスターファイルの登録を行ってください。",
          );
          this.isShowSpinner = false;
          return;
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        throw new Error(error);
      }
      this.isShowSpinner = false;
      this.isDisplayMaster = true;
    },
    // マスター新規登録
    uploadFile() {
      // 複数選択されていた場合、メッセージを表示する
      if (this.customerID === "") {
        //顧客名の選択漏れのチェック
        alert(
          "入力漏れ、もしくは選択漏れがあります。顧客の選択及び店舗の選択を行ってください。",
        );
        this.errorFlag.customerNotSelected = true;
        return;
      }
      //顧客errorFlag初期化
      this.errorFlag.customerNotSelected = false;
      if (this.stores.filter((e) => e.bool).length >= 2) {
        alert(this.$t("admin.customerList.selectOneCustomerInCaseOfEdit"));
        return;
      } else if (this.stores.filter((e) => e.bool).length <= 0) {
        this.errorFlag.storeNotSelected = true;
        alert(
          "入力漏れ、もしくは選択漏れがあります。顧客の選択及び店舗の選択を行ってください。",
        );
        return;
      }
      //編集画面
      const index = this.stores.map((e) => e.bool).indexOf(true);
      this.storeID = this.stores[index].id;
      this.$emit("edit", this.customerID, this.storeID);
    },
  },
  async created() {
    this.isShowSpinner = true;
    try {
      const isAdmin = await auth.isAdmin();
      if (!isAdmin) {
        throw new Error("Forbidden");
      }
      // 顧客一覧を表示
      const getCustomerList = await customerManager.getCustomerList();
      let records = {};
      getCustomerList.map(async (doc) => {
        records[doc.name] = doc.id;
      });
      this.customers = records;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    this.isShowSpinner = false;
  },
  beforeDestroy() {
    this.isShowSpinner = false;
  },
};
</script>

<style scoped>
.admin-category {
  padding: 20px;
}
.admin-category--main {
  padding: 10px;
}
.admin-category--button {
  margin-top: 20px;
}
.select-customer {
  padding: 0px;
}
.select-store-wrapper {
  text-align: left;
  border: 2px solid #e6e6e6;
  border-radius: 5px;
  margin-top: 20px;
  min-height: 25vh;
  overflow: auto;
}
.admin-select-store-checkbox {
  display: block;
  text-align: left;
  margin: 10px 0px 20px 0px;
}
.store-not-selected {
  border: 2px solid #e85951;
}
@media screen and (min-width: 641px) {
  .admin-select-master-wrapper {
    margin-top: 10px;
    display: grid;
    vertical-align: middle;
    grid-template-columns: 5fr 1fr;
    gap: 10px;
    min-height: 300px;
  }
  .select-store-wrapper {
    grid-template-columns: 5fr;
  }
  .admin-category--button-wrapper {
    grid-template-columns: 1fr;
    margin: auto;
    display: block;
  }
  .admin-category--button {
    padding: 5px;
  }
}
</style>
